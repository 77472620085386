import { createAction, props } from '@ngrx/store'
import { EntityMap, EntityMapOne, Update } from '@ngrx/entity'
import { IPaymentCollection } from '@modules/payment-collection/interfaces/payment-collection'

export const loadingPaymentCollection = createAction(
  '[PaymentCollection/API] Loading Payment Collections',
  props<{ loading: boolean }>()
)
export const loadPaymentCollections = createAction(
  '[PaymentCollection/API] Load Payment Collections',
  props<{ paymentCollections: IPaymentCollection[] }>()
)
export const selectPaymentCollection = createAction('[Users Page] Select Payment Collections', props<{ id: string }>())
export const setPaymentCollections = createAction(
  '[PaymentCollection/API] Set Payment Collections',
  props<{ paymentCollections: IPaymentCollection[] }>()
)
export const updatePaymentCollection = createAction(
  '[PaymentCollection/API] Update Payment Collection',
  props<{ update: Update<IPaymentCollection> }>()
)
export const mapPaymentCollection = createAction(
  '[PaymentCollection/API] Map Payment Collection',
  props<{ entityMap: EntityMapOne<IPaymentCollection> }>()
)
export const mapPaymentCollections = createAction(
  '[PaymentCollection/API] Map Payment Collections',
  props<{ entityMap: EntityMap<IPaymentCollection> }>()
)
export const clearPaymentCollections = createAction('[PaymentCollection/API] Clear Payment Collections')
