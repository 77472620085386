import { createAction, props } from '@ngrx/store'
import { EntityMap, EntityMapOne, Update } from '@ngrx/entity'
import { IWithdrawal } from '@modules/withdrawal/interfaces/withdrawal'

export const loadingWithdrawal = createAction('[Withdrawal/API] Loading Withdrawals', props<{ loading: boolean }>())
export const loadWithdrawals = createAction(
  '[Withdrawal/API] Load Withdrawals',
  props<{ withdrawals: IWithdrawal[] }>()
)
export const selectWithdrawal = createAction('[Withdrawal Page] Select Withdrawals', props<{ id: string }>())

export const setWithdrawals = createAction('[Withdrawal/API] Set Withdrawals', props<{ withdrawals: IWithdrawal[] }>())

export const setWithdrawal = createAction('[Withdrawal/API] Set Withdrawal', props<{ withdrawal: IWithdrawal }>())
export const upsertWithdrawal = createAction('[Withdrawal/API] Upsert Withdrawal', props<{ withdrawal: IWithdrawal }>())

export const updateWithdrawal = createAction(
  '[Withdrawal/API] Update Withdrawal',
  props<{ update: Update<IWithdrawal> }>()
)
export const updateWithdrawals = createAction(
  '[Withdrawal/API] Update Withdrawals',
  props<{ updates: Update<IWithdrawal>[] }>()
)
export const mapWithdrawal = createAction(
  '[Withdrawal/API] Map Withdrawal',
  props<{ entityMap: EntityMapOne<IWithdrawal> }>()
)
export const mapWithdrawals = createAction(
  '[Withdrawal/API] Map Withdrawals',
  props<{ entityMap: EntityMap<IWithdrawal> }>()
)
export const deleteWithdrawal = createAction('[Withdrawal/API] Delete Withdrawal', props<{ id: string }>())
export const deleteWithdrawals = createAction('[Withdrawal/API] Delete Withdrawals', props<{ ids: string[] }>())
export const clearWithdrawals = createAction('[Withdrawal/API] Clear Withdrawals')
