import { ICollaborator } from '@modules/collaborator/interfaces/collaborator'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import * as CollaboratorActions from '../actions/collaborator.actions'

export interface StateCollaborator extends EntityState<ICollaborator> {
  selectCollaboratorId?: string | number | null
  loading: boolean
}

export const adapter: EntityAdapter<ICollaborator> = createEntityAdapter<ICollaborator>()

export const initialState: StateCollaborator = adapter.getInitialState(<StateCollaborator>{
  loading: false,
})

export const collaboratorReducer = createReducer(
  initialState,
  on(CollaboratorActions.loadingCollaborator, (state, { loading }) => {
    return { ...state, loading: loading }
  }),
  on(CollaboratorActions.selectCollaborator, (state, { id }) => {
    return { ...state, selectCollaboratorId: id }
  }),
  on(CollaboratorActions.mapCollaborator, (state, { entityMap }) => {
    return adapter.mapOne(entityMap, state)
  }),
  on(CollaboratorActions.updateCollaborator, (state, { update }) => {
    return adapter.updateOne(update, state)
  }),
  on(CollaboratorActions.mapCollaborators, (state, { entityMap }) => {
    return adapter.map(entityMap, state)
  }),
  on(CollaboratorActions.loadCollaborators, (state, { collaborators }) => {
    return adapter.setAll(collaborators, state)
  }),
  on(CollaboratorActions.deleteCollaborator, (state, { id }) => {
    return adapter.removeOne(id, state)
  }),
  on(CollaboratorActions.setCollaborators, (state, { collaborators }) => {
    return adapter.setMany(collaborators, state)
  }),
  on(CollaboratorActions.clearCollaborators, state => {
    return adapter.removeAll({ ...state, selectCollaboratorId: null })
  })
)

// get the selectors
export const {
  // select the array of collaborators
  selectAll: selectAllCollaborators,
} = adapter.getSelectors()
