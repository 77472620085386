import { ICredit } from '@modules/credit/interfaces/credit'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import * as CreditActions from '../actions/credit.actions'

export interface StateCredit extends EntityState<ICredit> {
  selectCreditId?: string | number | null
  loading: boolean
}

export const adapter: EntityAdapter<ICredit> = createEntityAdapter<ICredit>()

export const initialState: StateCredit = adapter.getInitialState(<StateCredit>{
  loading: false,
})

export const creditReducer = createReducer(
  initialState,
  on(CreditActions.loadingCredit, (state, { loading }) => {
    return { ...state, loading: loading }
  }),
  on(CreditActions.selectCredit, (state, { id }) => {
    return { ...state, selectCreditId: id }
  }),
  on(CreditActions.mapCredit, (state, { entityMap }) => {
    return adapter.mapOne(entityMap, state)
  }),
  on(CreditActions.updateCredit, (state, { update }) => {
    return adapter.updateOne(update, state)
  }),
  on(CreditActions.mapCredits, (state, { entityMap }) => {
    return adapter.map(entityMap, state)
  }),
  on(CreditActions.loadCredits, (state, { credits }) => {
    return adapter.setAll(credits, state)
  }),
  on(CreditActions.deleteCredit, (state, { id }) => {
    return adapter.removeOne(id, state)
  }),
  on(CreditActions.setCredits, (state, { credits }) => {
    return adapter.setMany(credits, state)
  }),
  on(CreditActions.clearCredits, state => {
    return adapter.removeAll({ ...state, selectCreditId: null })
  })
)

// get the selectors
export const {
  // select the array of collaborators
  selectAll: selectAllCredits,
} = adapter.getSelectors()
