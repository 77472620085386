import { createAction, props } from '@ngrx/store'
import { Update, EntityMap, EntityMapOne } from '@ngrx/entity'
import { IAdmin } from '@modules/configuration/interfaces/configuration'

export const loadingAdmin = createAction('[Admin/API] Loading Admins', props<{ loading: boolean }>())
export const loadAdmins = createAction('[Admin/API] Load Admins', props<{ admins: IAdmin[] }>())
export const selectAdmin = createAction('[Users Page] Select Admins', props<{ id: string }>())
export const setAdmins = createAction('[Admin/API] Set Admins', props<{ admins: IAdmin[] }>())
export const addAdmin = createAction('[Admin/API] Add Admin', props<{ admin: IAdmin }>())
export const updateAdmin = createAction('[Admin/API] Update Admin', props<{ update: Update<IAdmin> }>())
export const mapAdmin = createAction('[Admin/API] Map Admin', props<{ entityMap: EntityMapOne<IAdmin> }>())
export const mapAdmins = createAction('[Admin/API] Map Admins', props<{ entityMap: EntityMap<IAdmin> }>())
export const deleteAdmin = createAction('[Admin/API] Delete Admin', props<{ id: string }>())
export const deleteAdmins = createAction('[Admin/API] Delete Admins', props<{ ids: string[] }>())

export const clearAdmins = createAction('[Admin/API] Clear Admins')
