import { IShop } from '@modules/shop/interfaces/shop'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import * as ShopActions from '../actions/shop.actions'

export interface StateShop extends EntityState<IShop> {
  selectShopId?: string | number | null
  loading: boolean
}

export const adapter: EntityAdapter<IShop> = createEntityAdapter<IShop>()

export const initialState: StateShop = adapter.getInitialState(<StateShop>{
  loading: false,
})

export const shopReducer = createReducer(
  initialState,
  on(ShopActions.loadingShop, (state, { loading }) => {
    return { ...state, loading: loading }
  }),
  on(ShopActions.selectShop, (state, { id }) => {
    return { ...state, selectShopId: id }
  }),
  on(ShopActions.mapShop, (state, { entityMap }) => {
    return adapter.mapOne(entityMap, state)
  }),
  on(ShopActions.updateShop, (state, { update }) => {
    return adapter.updateOne(update, state)
  }),
  on(ShopActions.mapShops, (state, { entityMap }) => {
    return adapter.map(entityMap, state)
  }),
  on(ShopActions.loadShops, (state, { shops }) => {
    return adapter.setAll(shops, state)
  }),
  on(ShopActions.deleteShop, (state, { id }) => {
    return adapter.removeOne(id, state)
  }),
  on(ShopActions.setShops, (state, { shops }) => {
    return adapter.setMany(shops, state)
  }),
  on(ShopActions.clearShops, state => {
    return adapter.removeAll({ ...state, selectShopId: null })
  })
)

// get the selectors
export const {
  // select the array of collaborators
  selectAll: selectAllShops,
} = adapter.getSelectors()
