import { Injectable } from '@angular/core'
import { IResourceMethodObservable, ResourceAction, ResourceRequestMethod } from '@ngx-resource/core'
import { BaseResource } from '@shared/resources/base.resource'
import { IAuthRepresentative } from '@modules/auth/interfaces/auth'

@Injectable({ providedIn: 'root' })
export class AuthResource extends BaseResource {
  @ResourceAction({
    path: '/representative_info',
    pathPrefix: 'v3',
    method: ResourceRequestMethod.Get,
  })
  me!: IResourceMethodObservable<null, IAuthRepresentative>

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/companies/get/my_company',
    pathPrefix: 'v3',
    map: (response: any) => {
      return response?.data
    },
  })
  myCompany: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Get,
    path: '/companies/{!id}',
    pathPrefix: 'v3',
    map: (response: any) => {
      return response?.data
    },
  })
  myCompanyData: IResourceMethodObservable<any, any> | any
}
