import {
  ApplicationRef,
  ComponentFactoryResolver,
  EmbeddedViewRef,
  Inject,
  Injectable,
  Injector,
  PLATFORM_ID,
  TemplateRef,
  Type,
} from '@angular/core'
import { DOCUMENT, isPlatformBrowser } from '@angular/common'

import { ModalInstance } from './modal-instance'
import { ModalStackService } from '@molecule/modal/services/modal-stack.service'
import { ModalComponent } from '@molecule/modal/modal.component'
import { ModalConfig, ModalOptions } from '@molecule/modal/config/modal.config'

export type Content<T> = string | TemplateRef<T> | Type<T>

@Injectable()
export class ModalService {
  private lastElementFocused: any

  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _appRef: ApplicationRef,
    private _injector: Injector,
    private _modalStack: ModalStackService,
    private applicationRef: ApplicationRef,
    @Inject(DOCUMENT) private _document: any,
    @Inject(PLATFORM_ID) private _platformId: any
  ) {
    this._addEvents()
  }

  /**
   * Add a new modal instance. This step is essential and allows to retrieve any modal at any time.
   * It stores an object that contains the given modal identifier and the modal itself directly in the `modalStack`.
   *
   * @param modalInstance The object that contains the given modal identifier and the modal itself.
   * @param force Optional parameter that forces the overriding of modal instance if it already exists.
   * @returns nothing special.
   */
  public addModal(modalInstance: ModalInstance, force?: boolean): void {
    this._modalStack.addModal(modalInstance, force)
  }

  /**
   * Retrieve a modal instance by its identifier.
   *
   * @param id The modal identifier used at creation time.
   */
  public getModal(id?: string): ModalComponent {
    return this._modalStack.getModal(id)
  }

  /**
   * Alias of `getModal` to retrieve a modal instance by its identifier.
   *
   * @param id The modal identifier used at creation time.
   */
  public get(id?: string): ModalComponent {
    return this.getModal(id)
  }

  /**
   * Open a given modal
   *
   * @param id The modal identifier used at creation time.
   * @param force Tell the modal to open top of all other opened modals
   */
  public open(id = 'main', force = false): boolean {
    return this._openModal(this.get(id), force)
  }

  /**
   * Close a given modal
   *
   * @param id The modal identifier used at creation time.
   */
  public close(data: any = null, id = 'main'): boolean {
    return this._closeModal(data, this.get(id))
  }

  /**
   * Close all opened modals
   */
  public closeAll(): void {
    this.getOpenedModals().forEach((instance: ModalInstance) => {
      this._closeModal(null, instance.modal)
    })
  }

  /**
   * Toggles a given modal
   * If the retrieved modal is opened it closes it, else it opens it.
   *
   * @param id The modal identifier used at creation time.
   * @param force Tell the modal to open top of all other opened modals
   */
  public toggle(id: string, force = false): boolean {
    return this._toggleModal(this.get(id), force)
  }

  /**
   * Retrieve all the created modals.
   *
   * @returns an array that contains all modal instances.
   */
  public getModalStack(): ModalInstance[] {
    return this._modalStack.getModalStack()
  }

  /**
   * Retrieve all the opened modals. It looks for all modal instances with their `visible` property set to `true`.
   *
   * @returns an array that contains all the opened modals.
   */
  public getOpenedModals(): ModalInstance[] {
    return this._modalStack.getOpenedModals()
  }

  /**
   * Retrieve the opened modal with highest z-index.
   *
   * @returns the opened modal with highest z-index.
   */
  public getTopOpenedModal(): ModalComponent {
    return this._modalStack.getTopOpenedModal()
  }

  /**
   * Get the higher `z-index` value between all the modal instances. It iterates over the `ModalStack` array and
   * calculates a higher value (it takes the highest index value between all the modal instances and adds 1).
   * Use it to make a modal appear foreground.
   *
   * @returns a higher index from all the existing modal instances.
   */
  public getHigherIndex(): number {
    return this._modalStack.getHigherIndex()
  }

  /**
   * It gives the number of modal instances. It's helpful to know if the modal stack is empty or not.
   *
   * @returns the number of modal instances.
   */
  public getModalStackCount(): number {
    return this._modalStack.getModalStackCount()
  }

  /**
   * Remove a modal instance from the modal stack.
   *
   * @param id The modal identifier.
   * @returns the removed modal instance.
   */
  public removeModal(id: string = 'main'): void {
    const modalInstance = this._modalStack.removeModal(id)
    if (modalInstance) {
      this._destroyModal(modalInstance.modal)
    }
  }

  /**
   * Close the latest opened modal if it has been declared as escapable
   * Using a debounce system because one or more modals could be listening
   * escape key press event.
   */
  public closeLatestModal(): void {
    this.getTopOpenedModal().close()
  }

  /**
   * Create dynamic ModalComponent
   * @param id The modal identifier used at creation time.
   * @param content The modal content ( string, templateRef or Component )
   */
  public create(options: ModalOptions = <ModalOptions>{}) {
    let instance: ModalComponent
    const id = options?.id || 'main'
    try {
      instance = this.getModal(id)
      instance.setData(options.componentProps)
      return instance
    } catch (e) {
      const componentFactory = this._componentFactoryResolver.resolveComponentFactory(ModalComponent)
      const ngContent = this._resolveNgContent(options.component)

      const componentRef = componentFactory.create(this._injector, ngContent)

      if (options.component instanceof Type) {
        componentRef.instance.contentComponent = options.component
      }
      componentRef.instance.identifier = id
      componentRef.instance.createFrom = 'service'

      if (typeof options.closable === 'boolean') {
        componentRef.instance.closable = options.closable
      }
      if (typeof options.escapable === 'boolean') {
        componentRef.instance.escapable = options.escapable
      }
      if (typeof options.dismissible === 'boolean') {
        componentRef.instance.dismissible = options.dismissible
      }
      if (typeof options.customClass === 'string') {
        componentRef.instance.customClass = options.customClass
      }
      if (typeof options.backdrop === 'boolean') {
        componentRef.instance.backdrop = options.backdrop
      }
      if (typeof options.force === 'boolean') {
        componentRef.instance.force = options.force
      }
      if (typeof options.hideDelay === 'number') {
        componentRef.instance.hideDelay = options.hideDelay
      }
      if (typeof options.autostart === 'boolean') {
        componentRef.instance.autostart = options.autostart
      }
      if (typeof options.target === 'string') {
        componentRef.instance.target = options.target
      }
      if (typeof options.ariaLabel === 'string') {
        componentRef.instance.ariaLabel = options.ariaLabel
      }
      if (typeof options.ariaLabelledBy === 'string') {
        componentRef.instance.ariaLabelledBy = options.ariaLabelledBy
      }
      if (typeof options.ariaDescribedBy === 'string') {
        componentRef.instance.ariaDescribedBy = options.ariaDescribedBy
      }

      this._appRef.attachView(componentRef.hostView)

      const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement
      this._document.body.appendChild(domElem)

      if (options.componentProps) {
        componentRef.instance.setData(options.componentProps)
      }
      return componentRef.instance
    }
  }

  private _addEvents(): boolean {
    if (!this.isBrowser) {
      return false
    }

    const evCreate = ((e: CustomEvent) => {
      this._initModal(e.detail.instance)
    }) as EventListener

    window.removeEventListener(ModalConfig.prefixEvent + 'create', evCreate)
    window.addEventListener(ModalConfig.prefixEvent + 'create', evCreate)

    const evDelete = ((e: CustomEvent) => {
      this._deleteModal(e.detail.instance)
    }) as EventListener

    window.removeEventListener(ModalConfig.prefixEvent + 'delete', evDelete)
    window.addEventListener(ModalConfig.prefixEvent + 'delete', evDelete)

    const evOpen = ((e: CustomEvent) => {
      this._openModal(e.detail.instance.modal, e.detail.extraData.top)
    }) as EventListener

    window.removeEventListener(ModalConfig.prefixEvent + 'open', evOpen)
    window.addEventListener(ModalConfig.prefixEvent + 'open', evOpen)

    const evClose = ((e: CustomEvent) => {
      this._closeModal(null, e.detail.instance.modal)
      this._deleteModal(e.detail.instance)
    }) as EventListener

    window.removeEventListener(ModalConfig.prefixEvent + 'close', evClose)
    window.addEventListener(ModalConfig.prefixEvent + 'close', evClose)

    const evDismiss = ((e: CustomEvent) => {
      this._dismissModal(e.detail.instance.modal)
    }) as EventListener

    window.removeEventListener(ModalConfig.prefixEvent + 'dismiss', evDismiss)
    window.addEventListener(ModalConfig.prefixEvent + 'dismiss', evDismiss)

    window.removeEventListener('keyup', this._escapeKeyboardEvent)
    window.addEventListener('keyup', this._escapeKeyboardEvent)

    return true
  }

  private _initModal(modalInstance: ModalInstance) {
    modalInstance.modal.layerPosition += this.getModalStackCount()
    this.addModal(modalInstance, modalInstance.modal.force)

    if (modalInstance.modal.autostart) {
      this.open(modalInstance.id)
    }
  }

  private _openModal(modal: ModalComponent, top?: boolean): boolean {
    if (modal.visible) {
      return false
    }

    this.lastElementFocused = document.activeElement

    if (modal.escapable) {
      window.addEventListener('keyup', this._escapeKeyboardEvent)
    }

    if (modal.backdrop) {
      window.addEventListener('keydown', this._trapFocusModal)
    }

    if (top) {
      modal.layerPosition = this.getHigherIndex()
    }

    modal.addBodyClass()
    modal.overlayVisible = true
    modal.visible = true
    modal.onOpen.emit(modal)
    modal.markForCheck()

    setTimeout(() => {
      modal.openedClass = true

      if (modal.target) {
        modal.targetPlacement()
      }

      modal.dialog.first.nativeElement.setAttribute('role', 'dialog')
      modal.dialog.first.nativeElement.setAttribute('tabIndex', '-1')
      modal.dialog.first.nativeElement.setAttribute('aria-modal', 'true')
      modal.dialog.first.nativeElement.focus()

      modal.markForCheck()
    })

    return true
  }

  private _toggleModal(modal: ModalComponent, top?: boolean): boolean {
    if (modal.visible) {
      return this._closeModal(null, modal)
    } else {
      return this._openModal(modal, top)
    }
  }

  private _closeModal(data: any = null, modal: ModalComponent): boolean {
    if (!modal.openedClass) {
      return false
    }

    modal.openedClass = false

    if (this.getOpenedModals().length < 2) {
      modal.removeBodyClass()
      window.removeEventListener('keyup', this._escapeKeyboardEvent)
      window.removeEventListener('keydown', this._trapFocusModal)
    }
    modal.onClose.emit({
      data,
      modal,
    })
    setTimeout(() => {
      modal.visible = false
      modal.overlayVisible = false
      modal.dialog.first.nativeElement.removeAttribute('tabIndex')
      modal.markForCheck()
      this.removeModal(modal.identifier)
    }, modal.hideDelay)

    return true
  }

  private _dismissModal(modal: ModalComponent): boolean {
    if (!modal.openedClass) {
      return false
    }

    modal.openedClass = false

    if (this.getOpenedModals().length < 2) {
      modal.removeBodyClass()
    }
    modal.onDismiss.emit(modal)
    setTimeout(() => {
      modal.visible = false
      modal.overlayVisible = false
      modal.markForCheck()
    }, modal.hideDelay)

    return true
  }

  private _deleteModal(modalInstance: ModalInstance) {
    this.removeModal(modalInstance.id)

    if (!this.getModalStack().length) {
      modalInstance.modal.removeBodyClass()
    }
  }

  /**
   * Resolve content according to the types
   * @param content The modal content ( string, templateRef or Component )
   */
  private _resolveNgContent<T>(content: Content<T>): any[][] | Text[][] {
    if (typeof content === 'string') {
      const element = this._document.createTextNode(content)
      return [[element]]
    }

    if (content instanceof TemplateRef) {
      const viewRef = content.createEmbeddedView(null as any)
      this.applicationRef.attachView(viewRef)
      return [viewRef.rootNodes]
    }

    return []
  }

  /**
   * Close the latest opened modal if escape key event is emitted
   * @param event The Keyboard Event
   */
  private _escapeKeyboardEvent = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      try {
        const modal = this.getTopOpenedModal()

        if (!modal.escapable) {
          return false
        }

        this.closeLatestModal()

        return true
      } catch (e) {
        return false
      }
    }

    return false
  }

  /**
   * Is current platform browser
   */
  private get isBrowser(): boolean {
    return isPlatformBrowser(this._platformId)
  }

  /**
   * While modal is open, the focus stay on it
   * @param event The Keyboar dEvent
   */
  private _trapFocusModal = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      try {
        const modal = this.getTopOpenedModal()

        if (!modal.dialog.first.nativeElement.contains(document.activeElement)) {
          event.preventDefault()
          event.stopPropagation()
          modal.dialog.first.nativeElement.focus()
        }

        return true
      } catch (e) {
        return false
      }
    }

    return false
  }

  /**
   * Remove dynamically created modal from DOM
   */
  private _destroyModal(modal: ModalComponent): void {
    // Prevent destruction of the inline modals
    if (modal.createFrom !== 'service') {
      return
    }

    if (modal.elementRef.nativeElement.parentNode === this._document.body) {
      this._document?.body?.removeChild(modal.elementRef.nativeElement)
    }
  }
}
