import { createAction, props } from '@ngrx/store'
import { EntityMap, EntityMapOne, Update } from '@ngrx/entity'
import { ICreditSetting } from '@modules/credit/interfaces/credit'

export const loadingCreditSetting = createAction(
  '[Credit/API] Loading Credits Settings',
  props<{
    loading: boolean
  }>()
)
export const loadCreditSettings = createAction(
  '[Credit/API] Load Credits Settings',
  props<{
    creditSettings: ICreditSetting[]
  }>()
)
export const selectCreditSetting = createAction('[Users Page] Select Credits Settings', props<{ id: string }>())
export const loadCreditSetting = createAction(
  '[Credit/API] Load Credit Setting',
  props<{
    id: string
  }>()
)
export const setCreditSetting = createAction(
  '[Credit/API] Set Credits Setting',
  props<{
    creditSetting: ICreditSetting
  }>()
)
export const removeCreditSetting = createAction('[Credit/API] Remove Credits Setting')
export const setCreditSettings = createAction(
  '[Credit/API] Set Credits Settings',
  props<{
    creditSettings: ICreditSetting[]
  }>()
)
export const updateCreditSetting = createAction(
  '[Credit/API] Update Credit Settings',
  props<{
    update: Update<ICreditSetting>
  }>()
)
export const deleteCreditSetting = createAction('[Credit/API] Delete Credit Settings', props<{ id: string }>())
export const mapCreditSetting = createAction(
  '[Credit/API] Map Credit Settings',
  props<{
    entityMap: EntityMapOne<ICreditSetting>
  }>()
)
export const mapCreditSettings = createAction(
  '[Credit/API] Map Credits Settings',
  props<{
    entityMap: EntityMap<ICreditSetting>
  }>()
)
export const clearCreditSettings = createAction('[Credit/API] Clear Credits Settings')
