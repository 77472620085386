import { IAdmin } from '@modules/configuration/interfaces/configuration'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import * as AdminActions from '../actions/admins.actions'

export interface StateAdmin extends EntityState<IAdmin> {
  selectAdminId?: string | number | null
  loading: boolean
}

export const adapter: EntityAdapter<IAdmin> = createEntityAdapter<IAdmin>()

export const initialState: StateAdmin = adapter.getInitialState(<StateAdmin>{
  loading: false,
})

export const adminReducer = createReducer(
  initialState,
  on(AdminActions.loadingAdmin, (state, { loading }) => {
    return { ...state, loading: loading }
  }),
  on(AdminActions.selectAdmin, (state, { id }) => {
    return { ...state, selectAdminId: id }
  }),
  on(AdminActions.addAdmin, (state, { admin }) => {
    return adapter.addOne(admin, state)
  }),
  on(AdminActions.updateAdmin, (state, { update }) => {
    return adapter.updateOne(update, state)
  }),
  on(AdminActions.mapAdmin, (state, { entityMap }) => {
    return adapter.mapOne(entityMap, state)
  }),
  on(AdminActions.mapAdmins, (state, { entityMap }) => {
    return adapter.map(entityMap, state)
  }),
  on(AdminActions.deleteAdmin, (state, { id }) => {
    return adapter.removeOne(id, state)
  }),
  on(AdminActions.deleteAdmins, (state, { ids }) => {
    return adapter.removeMany(ids, state)
  }),
  on(AdminActions.loadAdmins, (state, { admins }) => {
    return adapter.setAll(admins, state)
  }),
  on(AdminActions.setAdmins, (state, { admins }) => {
    return adapter.setMany(admins, state)
  }),
  on(AdminActions.clearAdmins, state => {
    return adapter.removeAll({ ...state, selectAdminId: null })
  })
)

// get the selectors
export const {
  // select the array of Admins
  selectAll: selectAllAdmins,
} = adapter.getSelectors()
