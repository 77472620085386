import { Injectable } from '@angular/core'
import { Config, DefaultConfig } from 'ngx-easy-table'

@Injectable()
export class TableService {
  #configuration!: Config

  constructor() {
    this.#configuration = {
      ...DefaultConfig,
    }
    this.#configuration.paginationRangeEnabled = false
    this.#configuration.paginationEnabled = false
    this.#configuration.paginationMaxSize = 20
    this.#configuration.checkboxes = false
    this.#configuration.rows = 100
    this.#configuration.serverPagination = true
    this.#configuration.threeWaySort = true
    this.#configuration.fixedColumnWidth = false
    this.#configuration.isLoading = false
    this.#configuration.horizontalScroll = false
  }

  /**
   * Get configuration
   * @returns {Config}
   */
  get config(): Config {
    return this.#configuration
  }

  /**
   * Set configuration
   * @param {Config} config
   */
  set config(config: Config) {
    this.#configuration = config
  }

  /**
   * Set configuration
   * @param config
   * @param value
   */
  setConfig(config: keyof Config, value: any) {
    // @ts-ignore
    this.#configuration[config] = value
  }

  /**
   * Get configuration
   * @param config
   * @returns {any}
   */
  getConfig(config: keyof Config) {
    return this.#configuration[config]
  }
}
