import { createReducer, on } from '@ngrx/store'
import * as AppActions from '../actions/app.actions'
import { IApp } from '@shared/interfaces/app'
import { ICompany } from '@modules/company/interfaces/company'
import { IUser } from '@modules/configuration/interfaces/configuration'

export interface StateApp extends IApp {
  loading: boolean
  user: IUser | null
  company: ICompany
}

export const initialState: IApp = {
  loading: false,
  user: null,
  company: null,
  catalogs: {},
  credit_settings: null,
}

export const appReducer = createReducer(
  initialState,
  on(AppActions.loadingApp, (state, { loading }) => {
    return { ...state, loading: loading }
  }),
  on(AppActions.setCompany, (state, { company }) => {
    return {
      ...state,
      company: company,
    }
  }),
  on(AppActions.updateCompany, (state, { company }) => {
    return {
      ...state,
      company: {
        ...state.company,
        ...company,
      },
    }
  }),
  on(AppActions.setCompanyCreditSettings, (state, { credit_settings }) => {
    return {
      ...state,
      credit_settings: credit_settings,
    }
  }),
  on(AppActions.setUser, (state, { user }) => {
    return { ...state, user: user }
  }),
  on(AppActions.clearCompany, state => {
    return { ...state, company: null }
  }),
  on(AppActions.updateUser, (state, { user }) => {
    return {
      ...state,
      user: {
        ...state.user,
        ...user,
      },
    }
  }),
  on(AppActions.setCatalog, (state, { catalog }) => {
    return { ...state, catalogs: { ...state.catalogs, ...catalog } }
  }),
  on(AppActions.clearCatalog, state => {
    return { ...state, catalogs: {} }
  })
)
