import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { MenuItemComponent } from './menu-item.component'
import { IconModule } from '../icon/icon.module'

@NgModule({
  declarations: [MenuItemComponent],
  imports: [CommonModule, IconModule],
  exports: [MenuItemComponent],
})
export class MenuItemModule {}
