import { Injectable } from '@angular/core'
import { SiteResource } from '@modules/site/site.resource'
import { ISite } from '@modules/site/interfaces/site'

@Injectable({
  providedIn: 'root',
})
export class SiteService {
  constructor(private _siteResource: SiteResource) {}

  getSiteTree() {
    this._siteResource.query().subscribe((response: ISite) => {
      //
    })
  }
}
