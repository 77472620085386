import { Injectable, Injector } from '@angular/core'
import { ToastrService as BaseToastrService } from 'ngx-toastr'
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config'

export interface IToastOptions extends Partial<IndividualConfig> {
  message: string
  title?: string
  type?: 'success' | 'info' | 'warning' | 'error'
}

@Injectable({ providedIn: 'root' })
export class ToastrService {
  #options: IToastOptions = <IToastOptions>{
    timeOut: 5000,
    closeButton: true,
    enableHtml: true,
  }
  private _toastService!: BaseToastrService

  constructor(private _injector: Injector) {
    this._toastService = this._injector.get(BaseToastrService)
  }

  // @ts-ignore
  override async success(config: Partial<IToastOptions>): Promise<any> {
    return this._display({
      type: 'success',
      ...this.#options,
      ...config,
    })
  }

  // @ts-ignore
  async error(config: Partial<IToastOptions>): Promise<any> {
    return this._display({
      type: 'error',
      ...this.#options,
      ...config,
    })
  }

  // @ts-ignore
  async warning(config: Partial<IToastOptions>): Promise<any> {
    return this._display({
      type: 'warning',
      ...this.#options,
      ...config,
    })
  }

  // @ts-ignore
  async info(config: Partial<IToastOptions>): Promise<any> {
    return this._display({
      type: 'info',
      ...this.#options,
      ...config,
    })
  }

  private async _display(config: Partial<IToastOptions>): Promise<any> {
    return this._toastService.show(config.message, config.title, config, config.type)
  }
}
