export const ModalConfig = {
  bodyClassOpen: 'dialog-open',
  prefixEvent: 'ngx-smart-modal.',
}

export interface ModalOptions {
  component: any
  id?: any
  componentProps?: { [key: string]: any }
  closable?: boolean
  escapable?: boolean
  dismissible?: boolean
  customClass?: string
  backdrop?: boolean
  force?: boolean
  hideDelay?: number
  autostart?: boolean
  target?: string
  ariaLabel?: string
  ariaLabelledBy?: string
  ariaDescribedBy?: string
}
