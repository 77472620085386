import { ICreditSetting } from '@modules/credit/interfaces/credit'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import * as CreditActions from '../actions/credit-settings.actions'

export interface StateCreditSetting extends EntityState<ICreditSetting> {
  selectCreditSettingId?: string | number | null
  loading: boolean
  entity: ICreditSetting | null
}

export const adapterCreditSetting: EntityAdapter<ICreditSetting> = createEntityAdapter<ICreditSetting>()

export const initialStateCreditSetting: StateCreditSetting = adapterCreditSetting.getInitialState(<StateCreditSetting>{
  loading: false,
})

export const creditSettingsReducer = createReducer(
  initialStateCreditSetting,
  on(CreditActions.loadingCreditSetting, (state, { loading }) => {
    return { ...state, loading: loading }
  }),
  on(CreditActions.selectCreditSetting, (state, { id }) => {
    return { ...state, selectCreditSettingId: id }
  }),
  on(CreditActions.mapCreditSetting, (state, { entityMap }) => {
    return adapterCreditSetting.mapOne(entityMap, state)
  }),
  on(CreditActions.updateCreditSetting, (state, { update }) => {
    return adapterCreditSetting.updateOne(update, state)
  }),
  on(CreditActions.mapCreditSettings, (state, { entityMap }) => {
    return adapterCreditSetting.map(entityMap, state)
  }),
  on(CreditActions.loadCreditSettings, (state, { creditSettings }) => {
    return adapterCreditSetting.setAll(creditSettings, state)
  }),
  on(CreditActions.deleteCreditSetting, (state, { id }) => {
    return adapterCreditSetting.removeOne(id, state)
  }),
  on(CreditActions.removeCreditSetting, state => {
    return { ...state, entity: null }
  }),
  on(CreditActions.setCreditSetting, (state, { creditSetting }) => {
    return {
      ...state,
      entity: {
        ...state.entity,
        ...creditSetting,
      },
    }
  }),
  on(CreditActions.setCreditSettings, (state, { creditSettings }) => {
    return adapterCreditSetting.setMany(creditSettings, state)
  }),
  on(CreditActions.clearCreditSettings, state => {
    return adapterCreditSetting.removeAll({ ...state, selectCreditSettingId: null })
  })
)

// get the selectors
export const {
  // select the array of collaborators
  selectAll: selectAllCredits,
} = adapterCreditSetting.getSelectors()
