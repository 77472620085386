import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { InputPasswordModule } from '@atom/input-password/input-password.module'
import { HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { ButtonModule } from '@atom/button/button.module'
import { ResourceModule } from '@ngx-resource/handler-ngx-http'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { IndicatorModule } from '@atom/indicator/indicator.module'
import { MenuItemModule } from '@atom/menu-item/menu-item.module'
import { IconModule } from '@atom/icon/icon.module'
import { InputTextModule } from '@atom/input-text'
import { BaseResource } from '@shared/resources/base.resource'
import { StoreModule } from '@ngrx/store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { environment } from '@environments/environment'
import { EffectsModule } from '@ngrx/effects'
import { collaboratorReducer } from '@modules/collaborator/state/reducers/collaborator.reducer'
import { ToastrModule } from 'ngx-toastr'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { registerLocaleData } from '@angular/common'
import localeES from '@angular/common/locales/es-MX'
import { TableModule } from 'ngx-easy-table'
import { ToastrService } from '@services/toastr.service'
import { appReducer } from '@shared/state/reducers/app.reducer'
import { withdrawalReducer } from '@modules/withdrawal/state/reducers/withdrawal.reducer'
import { ModalModule } from '@molecule/modal/modal.module'
import { SiteService } from '@modules/site/site.service'
import { siteReducer } from '@modules/site/state/reducers/site.reducer'
import { SiteEffects } from '@modules/site/state/effects/site.effect'
import { CookieService } from 'ngx-cookie-service'
import { SessionService } from '@services/session.service'
import { AuthResource } from '@shared/resources/auth.resource'
import { AppEffects } from '@shared/state/effets/app.effect'
import { CalendarModule, DateAdapter } from 'angular-calendar'
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'
import { periodReducer } from '@modules/period/state/reducers/period.reducer'
import { deductionReducer } from '@modules/deduction/state/reducers/deduction.reducer'
import { FlatpickrModule } from 'angularx-flatpickr'
import { companyReducer } from '@modules/company/state/reducers/company.reducer'
import { ListModule } from '@services/list/list.module'
import { variableIncomeBatchReducer } from '@modules/variable-income/state/reducers/variable-income-batch.reducer'
import { adminReducer } from '@modules/configuration/state/reducers/admins.reducer'
import { Router } from '@angular/router'
import * as Sentry from '@sentry/angular'
import { paymentCollectionReducer } from '@modules/payment-collection/state/reducers/payment-collection.reducer'
import { CompanyGuard } from '@shared/guards/company.guard'
import { groupReducer } from '@modules/group/state/reducers/group.reducer'
import { BaseFormBuilder } from '@services/base-formbuilder'
import { IdemPotencyService } from '@services/IdemPotency.service'
import { creditReducer } from '@modules/credit/state/reducers/credit.reducer'
import { shopReducer } from '@modules/shop/state/reducers/shop.reducer'
import { creditSettingsReducer } from '@modules/credit/state/reducers/credit-settings.reducer'
import { LetModule, PushModule } from '@ngrx/component'
import { InitService } from '@services/init.service'
import { CreditSettingEffects } from '@modules/credit/state/effets/credit-setting.effect'
import { ModalService } from '@molecule/modal/services/modal.service'

if (localeES[1]) {
  localeES[1] = [['AM', 'PM'], undefined, undefined]
}
registerLocaleData(localeES)

@NgModule({
  declarations: [AppComponent],
  imports: [
    LetModule,
    PushModule,
    ModalModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ResourceModule.forRoot(),
    ButtonModule,
    IndicatorModule,
    InputTextModule,
    MenuItemModule,
    IconModule,
    InputPasswordModule,
    FormsModule,
    ReactiveFormsModule,
    EffectsModule.forRoot([SiteEffects, AppEffects, CreditSettingEffects]),
    ToastrModule.forRoot(),
    TableModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    FlatpickrModule.forRoot(),
    StoreModule.forRoot(
      {
        collaborator: collaboratorReducer,
        app: appReducer,
        withdrawal: withdrawalReducer,
        site: siteReducer,
        period: periodReducer,
        credit: creditReducer,
        creditSettings: creditSettingsReducer,
        group: groupReducer,
        deduction: deductionReducer,
        company: companyReducer,
        variableIncomeBatch: variableIncomeBatchReducer,
        paymentCollection: paymentCollectionReducer,
        admin: adminReducer,
        shop: shopReducer,
      },
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      }
    ),
    !environment.production
      ? StoreDevtoolsModule.instrument({
          maxAge: 25,
          logOnly: environment.production,
          autoPause: true, // Pauses recording actions and state changes when the extension window is not open
        })
      : [],
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    ListModule,
  ],
  providers: [
    IdemPotencyService,
    BaseFormBuilder,
    CompanyGuard,
    SessionService,
    ToastrService,
    BaseResource,
    AuthResource,
    SiteService,
    CookieService,
    ModalService,
    { provide: LOCALE_ID, useValue: 'es-MX' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'MXN' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (_initService: InitService) => () => _initService.init(),
      deps: [InitService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
