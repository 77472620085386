import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ButtonComponent } from './button.component'
import { IconModule } from '../icon/icon.module'
import { IndicatorModule } from '../indicator/indicator.module'

@NgModule({
  declarations: [ButtonComponent],
  imports: [CommonModule, IconModule, IndicatorModule],
  exports: [ButtonComponent],
})
export class ButtonModule {}
