import { BehaviorSubject, Observable } from 'rxjs'
import { Injectable } from '@angular/core'

export interface IPagination {
  limit: number
  offset: number
  page: number
  order?: string | null
  filter?: any
  count: number | null
}

@Injectable()
export class PaginationService {
  #pagination: BehaviorSubject<IPagination> = new BehaviorSubject<IPagination>({
    limit: 25,
    count: null,
    offset: 0,
    page: 1,
    order: null,
    filter: null,
  })

  /**
   * Event for change pagination and update an request new list
   *
   * @param paginator
   */
  onPageChanged({ page, limit }: any): void {
    const pagination = this.#pagination.getValue()
    this.#pagination.next({
      ...pagination,
      page: page,
      offset: this.getOffset(page, limit),
      limit,
    })
  }

  /**
   * Calculate offset for pagination
   * @param {number} page
   * @param {number} limit
   * @returns {number}
   */
  getOffset(page: number, limit: number): number {
    return (+page - 1) * +limit
  }

  /**
   * Get pagination Observable
   * @returns {IPagination}
   */
  get pagination$(): Observable<IPagination> {
    return this.#pagination.asObservable()
  }

  /**
   * Get pagination
   * @returns {IPagination}
   */
  get pagination(): IPagination {
    return this.#pagination.getValue()
  }

  /**
   * Set pagination
   * @param {IPagination} pagination
   */
  set pagination(pagination: IPagination) {
    this.#pagination.next(pagination)
  }

  /**
   * Calculate offset for pagination
   * @param {number} page
   * @param {number} limit
   * @returns {number}
   */
  reset(): IPagination {
    this.pagination = {
      limit: this.pagination.limit,
      page: 1,
      offset: 0,
      count: null,
    }
    return this.pagination
  }
}
