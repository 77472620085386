import { NgModule } from '@angular/core'
import { PaginationService } from '@services/list/pagination.service'
import { UrlListStateService } from '@services/list/url-list-state.service'
import { TableService } from '@services/list/table.service'
import { BaseListService } from '@services/base-list.service'

@NgModule({
  declarations: [],
  providers: [BaseListService, PaginationService, UrlListStateService, TableService],
})
export class ListModule {}
