import { environment } from '@environments/environment'
import * as moment from 'moment-timezone'
import { MenuType, UserType } from '@modules/site/interfaces/site'
import {
  ECommissionTypeLabel,
  ECommissionTypeValues,
  ECommissionValueTypeLabel,
  ECommissionValueTypeValues,
} from '@modules/commission/interfaces/commission'

export const cleanObject = (obj: Record<any, any>): Record<any, any> => {
  const result = { ...obj }
  Object.keys(result).forEach(key => {
    if (result[key] === null || result[key] === undefined || (result[key] + '').trim() === '') {
      delete result[key]
    }
  })
  return result
}

export const toOrdinal = (number = 0, gender = 'm') => {
  const ordinalTextMapping = [
    // unidades
    ['', 'primer', 'segundo', 'tercero', 'cuarto', 'quinto', 'sexto', 'séptimo', 'octavo', 'noveno'],
    // decenas
    [
      '',
      'décimo',
      'vigésimo',
      'trigésimo',
      'cuadragésimo',
      'quincuagésimo',
      'sexagésimo',
      'septuagésimo',
      'octagésimo',
      'nonagésimo',
    ],
    // centenas
    [
      '',
      'centésimo',
      'ducentésimo',
      'tricentésimo',
      'cuadrigentésimo',
      'quingentésimo',
      'sexcentésimo',
      'septingentésimo',
      'octingentésimo',
      'noningentésimo',
    ],
    // unidades de millar
    [
      '',
      'milésimo',
      'dosmilésimo',
      'tresmilésimo ',
      'cuatromilésimo',
      'cincomilésimo',
      'seismilésimo',
      'sietemilésimo',
      'ochomilésimo',
      'nuevemilésimo',
    ],
  ]
  let ordinal = ''
  let digits = [...number.toString()]
  digits.forEach((digit, i) => {
    // @ts-ignore
    let digit_ordinal = ordinalTextMapping[digits.length - i - 1][digit]
    if (!digit_ordinal) return
    if (gender === 'f') digit_ordinal = digit_ordinal.substr(0, [digit_ordinal.length - 1]) + 'a'
    ordinal += digit_ordinal + ' '
  })
  return ordinal.trim()
}

export const CPaymentFrequencies: Record<number, string> = {
  0: 'Semanal',
  1: 'Decenal',
  2: 'Catorcenal',
  3: 'Quincenal',
  4: 'Mensual',
}

export const paymentFrequencies: {
  id: number
  frequencyId?: string
  dayEndMin: number
  dayEndMax: number
  day?: number
  value: string
}[] = [
  {
    id: 0,
    day: 7,
    dayEndMin: 5,
    dayEndMax: 9,
    value: CPaymentFrequencies[0],
  },
  {
    id: 1,
    day: 12,
    dayEndMin: 8,
    dayEndMax: 12,
    value: CPaymentFrequencies[1],
  },
  {
    id: 2,
    day: 14,
    dayEndMin: 12,
    dayEndMax: 16,
    value: CPaymentFrequencies[2],
  },
  {
    id: 3,
    day: 15,
    dayEndMin: 13,
    dayEndMax: 17,
    value: CPaymentFrequencies[3],
  },
  {
    id: 4,
    day: 30,
    dayEndMin: 28,
    dayEndMax: 33,
    value: CPaymentFrequencies[4],
  },
]

export const payrollManagements = [
  { id: 0, value: 'Interna' },
  { id: 1, value: 'Externa' },
  { id: 2, value: 'Mixta' },
]

export const commissionTypeValues = [
  { id: ECommissionValueTypeValues.percentage, value: ECommissionValueTypeLabel.percentage },
  { id: ECommissionValueTypeValues.currency, value: ECommissionValueTypeLabel.currency },
]

export const commissionType = [
  { id: ECommissionTypeValues.staggered, value: ECommissionTypeLabel.staggered },
  { id: ECommissionTypeValues.fixed, value: ECommissionTypeLabel.fixed },
]

export const setTimeZone = (date: string | Date, tz = localStorage.getItem('timezone')): moment.Moment => {
  moment.locale('es-MX')
  return moment(date).tz(<string>tz, true)
}

export const momentTz = (date: string | Date, tz = localStorage.getItem('timezone')): string => {
  return setTimeZone(date, tz).format()
}

export const toLocalTz = (date: string | Date) => {
  return moment(moment(date).parseZone().tz(moment.tz.guess(), true).toISOString(true))
}

export enum EDayWeek {
  'monday' = 'Lunes',
  'tuesday' = 'Martes',
  'wednesday' = 'Miércoles',
  'thursday' = 'Jueves',
  'friday' = 'Viernes',
  'saturday' = 'Sábado',
  'sunday' = 'Domingo',
}

export const urlLogin = () => {
  return environment.LANDING + '?login=true&typeLogin=company'
}

export const checkAdminParams = (roles: UserType[], menuType?: MenuType | undefined): boolean => {
  if (menuType === undefined) {
    return true
  }
  return !!(menuType === MenuType.SUBMENU)
}

/**
 * Get diff between two objects
 * @param obj1
 * @param obj2
 */
export const diffObject = (obj1: Record<any, any>, obj2: Record<any, any>) => {
  let diff: Record<any, any> = {}

  for (let clave in obj1) {
    if (Array.isArray(obj1[clave]) && Array.isArray(obj2[clave])) {
      if (JSON.stringify(obj1[clave]) !== JSON.stringify(obj2[clave])) {
        diff[clave] = obj2[clave]
      }
    } else if (typeof obj1[clave] === 'object' && typeof obj2[clave] === 'object') {
      let subDiff = diffObject(obj1[clave], obj2[clave])
      if (Object.keys(subDiff).length > 0) {
        diff[clave] = subDiff
      }
    } else if (obj1[clave] !== obj2[clave]) {
      diff[clave] = obj2[clave]
    }
  }

  for (let clave in obj2) {
    if (!obj1.hasOwnProperty(clave)) {
      diff[clave] = obj2[clave]
    }
  }

  return diff
}
