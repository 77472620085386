import { IconModule } from '@atom/icon/icon.module'
import { IndicatorModule } from '@atom/indicator/indicator.module'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { NgModule } from '@angular/core'
import InputPasswordComponent from './input-password.component'
import { CommonModule } from '@angular/common'

@NgModule({
  declarations: [InputPasswordComponent],
  exports: [InputPasswordComponent],
  imports: [FormsModule, ReactiveFormsModule, IndicatorModule, IconModule, CommonModule],
})
export class InputPasswordModule {}
