import { Injectable } from '@angular/core'
import { UserType } from '@modules/site/interfaces/site'
import { updateUser } from '@shared/state/actions/app.actions'
import { Store } from '@ngrx/store'
import { StateCompany } from '@modules/company/state/reducers/company.reducer'
import { AuthResource } from '@shared/resources/auth.resource'
import { IAuthRole } from '@modules/auth/interfaces/auth'
import { SessionService } from '@services/session.service'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class InitService {
  constructor(
    protected _store: Store<{ company: StateCompany }>,
    protected _authResource: AuthResource,
    protected _sessionService: SessionService,
    protected _router: Router
  ) {}

  init(): Promise<unknown> {
    return new Promise(async resolve => {
      if (!(await this._sessionService?.getSession())) {
        resolve(true)
        return
      }
      this._authResource.me().subscribe({
        next: response => {
          if (response?.user?.complete_roles?.roles?.length) {
            const roles = response?.user?.complete_roles?.roles?.map((role: IAuthRole) => role.name)
            this._sessionService.setRoles(roles)
            this._store.dispatch(
              updateUser({
                user: {
                  role: this._sessionService.recoveryRole() as UserType,
                  finance_company: response?.user?.representative?.finance_company,
                },
              })
            )
            const hasRoleRequiredMFA = roles.some(role => response.user?.required_mfa?.includes(role))
            if (hasRoleRequiredMFA && response.user?.has_mfa === false) {
              this._router.navigate(['/auth/2fa-register-device'])
            }
          }
          resolve(true)
        },
        error: () => {
          resolve(true)
        },
      })
    })
  }
}
