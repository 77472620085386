import { Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { environment } from '@environments/environment'
import posthog from 'posthog-js'
import { Router } from '@angular/router'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  constructor(private title: Title, private _router: Router) {
    if (environment.production) {
      posthog.init('phc_niW4w4tBKmtNhv6JEtWM7IwGmk5pZkrX4WE5QsDspV8', { api_host: 'https://app.posthog.com' })
    }
  }

  ngOnInit() {
    if (!environment.production) {
      this.title.setTitle('Dev - Monto')
    }
  }
}
