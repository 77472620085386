export const environment = {
  production: false,
  API: 'https://dev-services.monto.mx/core/',
  LANDING: 'https://new-landing.monto.mx',
  COOKIE: {
    APPLICATION: '_tkn_develop',
    APPLICATION_REFRESH: '_tknr_develop',
    SESSION: '_session_develop',
    SESSION_CHALLENGE_NAME: '_challenge_name_develop',
    SESSION_IDENTIFIER: '_identifier_develop',
    ROLES: 'roles',
  },
}
