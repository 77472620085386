import { ILimitsWithdrawal, ILimitWithdrawal } from '@modules/withdrawal/interfaces/withdrawal'

export enum ECommissionType {
  STAGGERED = 'staggered',
  FIXED = 'fixed',
}
export enum ECommissionTypeValues {
  staggered = 1,
  fixed = 0,
}
export enum ECommissionTypeLabel {
  staggered = 'Escalonada',
  fixed = 'Fija',
}

export enum ECommissionValueTypeValues {
  currency = 0,
  percentage = 1,
}

export enum ECommissionValueType {
  CURRENCY = 'currency',
  PERCENTAGE = 'percentage',
}

export enum ECommissionValueTypeLabel {
  currency = 'MXN',
  percentage = 'Porcentaje',
}

export enum ECommissionTypeFree {
  FREE_TIME_PERIOD = 'free_time_period',
  FREE_WITHDRAWALS = 'free_withdrawals',
  FREE_FIRST_WITHDRAWALS = 'free_first_withdrawals',
}

export enum ECommissionTypeFreeValues {
  FREE_TIME_PERIOD = 1,
  FREE_WITHDRAWALS = 0,
  FREE_FIRST_WITHDRAWALS = 2,
}

export interface ICommissionFree {
  free_commission_type: ECommissionTypeFree | ECommissionTypeFreeValues
  start_date: string
  end_date: string
  'is_company_paid?': boolean
  consider_first_time_monto: boolean
  starting_free_withdrawals: number
  withdrawals_before_free_commission: number
  withdrawals: number
}

export interface ICommissionStaggered {
  id: string
  step_number: number
  min_quantity: number
  max_quantity: number
  commission_value: number
}
export enum ECommissionLimitType {
  COMPANY = 'company',
  PAYMENT_FREQUENCY = 'payment_frequency',
  PAYROLL_PERIOD = 'payroll_period',
  GROUP = 'group',
}

export interface ICommission {
  id: string
  commission_type: ECommissionType | ECommissionTypeValues
  commission_value_type: ECommissionValueType | ECommissionValueTypeValues
  commission_value: number
  'include_iva?': boolean
  free_commission: ICommissionFree | null
  level: undefined | null
  staggered_commission_steps: ICommissionStaggered[] | null
  group_id: string
  payroll_period_id: string
  company_id: string
  payment_frequency_id: string
  type: ECommissionLimitType
}

export interface ICommissions {
  base: ICommission | null
  variable: ICommission | null
}

export interface ICommissionServiceResponse {
  data: { limits: ILimitWithdrawal[]; commissions: ICommission[] }
}

export interface ICommissionServiceForSave {
  commissions: ICommissions
  limits: ILimitsWithdrawal
  idFromSaveData: () => {
    company_id?: string
    group_id?: string
    payroll_period_id?: string
    payment_frequency_id?: string
  }
}
