import { createReducer, on } from '@ngrx/store'
import * as SiteActions from '../actions/site.actions'
import { IModule, IModuleView, ISite } from '@modules/site/interfaces/site'

export interface StateSite {
  loading: boolean
  site: ISite[]
  //TODO: resolve iterface
  selectModule?: IModule | null
  selectView: IModuleView | null
}

export const initialStateSite: StateSite = {
  loading: false,
  site: [],
  selectModule: null,
  selectView: null,
}

export const siteReducer = createReducer(
  initialStateSite,
  on(SiteActions.loadingSite, (state, { loading }): StateSite => {
    return { ...state, loading: loading }
  }),
  on(SiteActions.setSite, (state, { site }): StateSite => {
    return { ...state, site: site }
  }),
  on(SiteActions.selectModule, (state, { selectModule }): StateSite => {
    const find = state?.site?.find(
      x => x.dashboard_module.id === selectModule?.id || x.dashboard_module.path === selectModule?.id
    )
    const moduleSelected = find ? { ...find.dashboard_module, views: find.views } : null
    return { ...state, selectModule: moduleSelected, selectView: null }
  }),
  on(SiteActions.deleteModule, (state, {}): StateSite => {
    return {
      ...state,
      selectModule: null,
      selectView: null,
    }
  }),
  on(SiteActions.deleteView, (state, { id }): StateSite => {
    return {
      ...state,
      selectView: state.selectModule?.views.find(x => x.is_default) || null,
      site: state.site.map((module: ISite) => {
        return {
          ...module,
          views: module.views.filter((view: IModuleView) => view.id !== id),
        }
      }),
    }
  }),
  on(SiteActions.selectView, (state, { selectView }): StateSite => {
    const find = state?.selectModule?.views.find(x => x.id === selectView?.id) || null
    return { ...state, selectView: find }
  })
)
