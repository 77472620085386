import { ConfigurationResource } from '@modules/configuration/configuration.resource'
import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import type { Observable } from 'rxjs'
import { debounceTime } from 'rxjs'
import { finalize, map, switchMap, tap } from 'rxjs/operators'
import { StateApp } from '@shared/state/reducers/app.reducer'
import * as CreditSettingActions from '@modules/credit/state/actions/credit-settings.actions'
import { loadingCreditSetting, setCreditSetting } from '@modules/credit/state/actions/credit-settings.actions'
import { SessionService } from '@services/session.service'
import { ICreditSetting } from '@modules/credit/interfaces/credit'
import { CreditSettingResource } from '@modules/credit/credit-setting.resource'

@Injectable()
export class CreditSettingEffects {
  loadCreditSetting$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CreditSettingActions.loadCreditSetting),
      debounceTime(500),
      tap(() => {
        this._store.dispatch(loadingCreditSetting({ loading: true }))
      }),
      switchMap((action): Observable<any> => {
        return this._resource
          .get({
            id: action?.id,
          })
          .pipe(
            finalize(() => {
              this._store.dispatch(loadingCreditSetting({ loading: false }))
            }),
            map((response: { data: ICreditSetting }) => {
              return setCreditSetting({ creditSetting: response.data })
            })
          )
      })
    )
  })

  constructor(
    private actions$: Actions,
    private _resource: CreditSettingResource,
    private _configurationResource: ConfigurationResource,
    private _store: Store<{ app: StateApp }>,
    protected _sessionService: SessionService
  ) {}
}
