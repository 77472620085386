import { createAction, props } from '@ngrx/store'
import { IModule, ISite, IModuleView } from '@modules/site/interfaces/site'

export const loadingSite = createAction('[Site/API] Loading Site', props<{ loading: boolean }>())
export const loadSite = createAction('[Site/API] Load Site')
export const setSite = createAction('[Site/API] Set Site', props<{ site: ISite[] }>())
export const selectModule = createAction('[Site/API] Select Module', props<{ selectModule: Partial<IModule> }>())
export const deleteModule = createAction('[Site/API] delete Module')
export const deleteView = createAction('[Site/API] delete View', props<{ id: string }>())
export const selectView = createAction('[Site/API] Select View', props<{ selectView: Partial<IModuleView> }>())
