import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable, take } from 'rxjs'
import { ActivatedRoute, Params, Router } from '@angular/router'

export interface IStatePageQuery {
  limit: number
  offset: number
  page: number
  moduleId?: string
  viewId?: string | null
}

@Injectable()
export class UrlListStateService {
  /**
   * BehaviorSubject for urlState
   * @type {BehaviorSubject<IStatePageQuery>}
   * @private
   */
  #urlState: BehaviorSubject<IStatePageQuery> = new BehaviorSubject<IStatePageQuery>(<IStatePageQuery>{})

  constructor(private _router: Router, private _route: ActivatedRoute) {}

  /**
   * Get urState Observable
   * @returns {IStatePageQuery}
   */
  get urState$(): Observable<IStatePageQuery> {
    return this.#urlState.asObservable()
  }

  /**
   * Get urlState
   * @returns {IStatePageQuery}
   */
  get urlState(): IStatePageQuery {
    return this.#urlState.getValue()
  }

  /**
   * Set urlState
   * @param {IStatePageQuery} urlState
   */
  set urlState(urlState: IStatePageQuery) {
    this.#urlState.next(urlState)
  }

  /**
   * Set params in query params for no lost navigation un urState on refresh page
   *
   */
  setQueryParams(): void {
    const urlTree = this._router.createUrlTree([], {
      relativeTo: this._route,
      queryParams: {
        page: +this.urlState.page,
        moduleId: this.urlState.moduleId,
        viewId: this.urlState.viewId,
        offset: +this.urlState.offset,
        limit: +this.urlState.limit,
      },
      queryParamsHandling: 'merge',
      preserveFragment: true,
    })
    this._router.navigateByUrl(urlTree, {
      replaceUrl: true,
    })
  }

  queryParams(): Observable<Params> {
    return this._route.queryParams.pipe(take(1))
  }
}
