import { ICostCenter, IDeduction } from '@modules/deduction/interfaces/deduction'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import * as DeductionActions from '../actions/deduction.actions'
import { ICommission } from '@modules/commission/interfaces/commission'
import { ILimitWithdrawal } from '@modules/withdrawal/interfaces/withdrawal'

export interface StateDeduction extends EntityState<IDeduction> {
  selectDeductionId?: string | number | null
  loading: boolean
  commissions: Record<string, ICommission>
  limits: Record<string, ILimitWithdrawal>
  cost_centers: Record<string, ICostCenter> | null
}

export const adapter: EntityAdapter<IDeduction> = createEntityAdapter<IDeduction>()

export const initialState: StateDeduction = adapter.getInitialState(<StateDeduction>{})

export const deductionReducer = createReducer(
  initialState,
  on(DeductionActions.loadingDeduction, (state, { loading }) => {
    return { ...state, loading: loading }
  }),
  on(DeductionActions.selectDeduction, (state, { id }) => {
    return { ...state, selectDeductionId: id }
  }),
  on(DeductionActions.addFutureDeductions, (state, { entitites }) => {
    const reversedEntities = [...entitites]
    reversedEntities.reverse()
    return adapter.setAll([...reversedEntities, ...(adapter.getSelectors().selectAll(state) || [])], state)
  }),
  on(DeductionActions.mapDeduction, (state, { entityMap }) => {
    return adapter.mapOne(entityMap, state)
  }),
  on(DeductionActions.updateDeduction, (state, { update }) => {
    return adapter.updateOne(update, state)
  }),
  on(DeductionActions.mapDeductions, (state, { entityMap }) => {
    return adapter.map(entityMap, state)
  }),
  on(DeductionActions.loadDeductions, (state, { deductions }) => {
    return adapter.setAll(deductions, state)
  }),
  on(DeductionActions.setDeductions, (state, { deductions }) => {
    return adapter.setMany(deductions, state)
  }),
  on(DeductionActions.setCostCenters, (state, { cost_centers }) => {
    return { ...state, cost_centers: cost_centers }
  }),
  on(DeductionActions.clearDeductions, state => {
    return adapter.removeAll({ ...state, selectDeductionId: null })
  })
)

// get the selectors
export const {
  // select the array of deductions
  selectAll: selectAllDeductions,
} = adapter.getSelectors()
