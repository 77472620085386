import { Injectable } from '@angular/core'
import {
  IResourceActionInner,
  IResourceMethodObservable,
  IResourceResponse,
  Resource,
  ResourceAction,
  ResourceGlobalConfig,
  ResourceHandler,
  ResourceParams,
  ResourceRequestMethod,
  ResourceResponseBodyType,
} from '@ngx-resource/core'
import { environment } from 'src/environments/environment'
import { Store } from '@ngrx/store'
import { StateApp } from '@shared/state/reducers/app.reducer'
import { loadingApp } from '@shared/state/actions/app.actions'
import { SessionService } from '@services/session.service'
import { cleanObject, urlLogin } from '@services/helpers'
import { IGroup } from '@modules/group/interfaces/group'
import { HttpClient } from '@angular/common/http'
import { catchError, from, map, mergeMap, Observable, of, switchMap, throwError } from 'rxjs'
import { UserType } from '@modules/site/interfaces/site'

export interface IErrorResponse {
  errors: string | string[]
}

export interface ISuccessResponse {
  data?: any
}

ResourceGlobalConfig.url = environment.API + 'api/'
ResourceGlobalConfig.headers = {
  'Content-Type': 'application/json',
  Accept: 'application/vnd.mto.v3+json',
}

@Injectable({ providedIn: 'root' })
@ResourceParams({
  pathPrefix: '',
})
export class BaseResource extends Resource {
  tryRefresh = 0
  @ResourceAction({
    method: ResourceRequestMethod.Post,
    isArray: false,
    path: '/index',
    responseBodyType: ResourceResponseBodyType.Json,
  })
  query?: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    method: ResourceRequestMethod.Post,
    isArray: false,
    path: '/index',
    params: {
      offset: 0,
      limit: 25,
    },
    responseBodyType: ResourceResponseBodyType.Json,
    map: (groups: { data: IGroup[] }) => {
      return groups.data
    },
  })
  catalog?: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    path: '/export',
    responseBodyType: ResourceResponseBodyType.Blob,
  })
  report?: IResourceMethodObservable<any, any> | any

  @ResourceAction({
    path: '/{!id}',
  })
  get?: IResourceMethodObservable<any, object> | any

  @ResourceAction({
    path: '/',
    method: ResourceRequestMethod.Post,
  })
  store?: IResourceMethodObservable<object, object> | any

  @ResourceAction({
    method: ResourceRequestMethod.Put,
    path: '/{!id}',
  })
  update?: IResourceMethodObservable<object, object> | any

  @ResourceAction({
    method: ResourceRequestMethod.Delete,
    path: '/{!id}',
  })
  delete?: IResourceMethodObservable<object, object> | any

  constructor(
    requestHandler: ResourceHandler,
    private _store: Store<{ app: StateApp }>,
    private _sessionService: SessionService,
    private _httpClient: HttpClient
  ) {
    super(requestHandler)
  }

  override $handleSuccessResponse(options: IResourceActionInner, resp: IResourceResponse): any {
    this._store?.dispatch(loadingApp({ loading: false }))

    return super.$handleSuccessResponse(options, resp)
  }

  override async $getHeaders(actionOptions: any) {
    this._store?.dispatch(loadingApp({ loading: true }))
    const headers = cleanObject({
      ...super.$getHeaders(actionOptions),
      ...ResourceGlobalConfig.headers,
      ...actionOptions.headers,
    })
    const token = await this._sessionService?.getSession()
    const role = await this._sessionService?.getType()

    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    headers['X-Client'] = role || UserType.ADMIN

    return headers
  }

  // @ts-ignore
  protected override $_createMainObservable(options: IResourceActionInner): Observable<any> {
    const requestPreparationPromise = this.$_setResolvedOptions(options).then(o => this.$_createRequestOptions(o))
    return of(requestPreparationPromise).pipe(
      switchMap(oPromise => {
        return (
          oPromise
            // @ts-ignore
            .then(o => {
              if (!o.requestOptions) {
                throw new Error('IResourceActionInner miss request options')
              }
              const handlerResp = this.requestHandler.handle(o.requestOptions)
              if (o.returnData && this.$_canSetInternalData(options)) {
                o.returnData.$abort = handlerResp.abort
              }
              if (handlerResp.observable) {
                return handlerResp.observable
              }
              return handlerResp.promise
            })
        )
      }),
      switchMap(s => (s instanceof Observable ? s : of(s))),
      map(resp => this.$handleSuccessResponse(options, resp)),
      catchError(resp => {
        if (resp?.status === 403) {
          if (this.tryRefresh >= 2) {
            this.tryRefresh = 0
            this._sessionService.destroySession()
            window.location.href = urlLogin()
            return of(null)
          }
          this.tryRefresh++
          this._sessionService.deleteSession()
          return this.refreshToken(options)
        }
        throw this.$handleErrorResponse(options, resp)
      })
    )
  }

  override $handleErrorResponse(options: IResourceActionInner, resp: IResourceResponse) {
    this._store?.dispatch(loadingApp({ loading: false }))
    //TODO:  check permissions
    if (resp?.status === 401) {
      this.removeSession()
      return
    }
    if (options.returnData && this.$_canSetInternalData(options)) {
      options.returnData.$resolved = true
    }
    if (options.actionAttributes && options.actionAttributes.onError) {
      options.actionAttributes.onError(resp)
    }
    throw resp
  }

  refreshToken(options: IResourceActionInner): Observable<any> {
    return from(this._sessionService.getSessionRefresh()).pipe(
      map((token: any) => {
        if (!token) {
          this.removeSession()
          return throwError('no token for refresh')
        }
        return token
      }),
      mergeMap((token: string) => {
        return this._httpClient.post(
          `${environment.API}api/v3/sessions/refresh_token`,
          { refresh_token: token },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/vnd.mto.v3+json',
            },
          }
        )
      }),
      map((response: any) => {
        if (options.requestOptions?.headers && options.requestOptions.headers['Authorization']) {
          options.requestOptions.headers['Authorization'] = `Bearer ${response.token}`
        }
        if (options.resolvedOptions && options.resolvedOptions.headers['Authorization']) {
          options.resolvedOptions.headers['Authorization'] = `Bearer ${response.token}`
        }
        this._sessionService.setSession(response?.token)
        return response
      }),
      switchMap(() => this.$restAction(options)),
      // @ts-ignore
      catchError(() => {
        this.removeSession()
      })
    )
  }

  removeSession(): void {
    this._sessionService.destroySession().then(() => {
      window.location.href = urlLogin()
    })
  }
}
