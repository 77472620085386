import { ICompany } from '@modules/company/interfaces/company'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import * as CompanyActions from '../actions/company.actions'

export interface StateCompany extends EntityState<ICompany> {
  selectCompanyId?: string | number | null
  loading: boolean
}

export const adapter: EntityAdapter<ICompany> = createEntityAdapter<ICompany>()

export const initialState: StateCompany = adapter.getInitialState(<StateCompany>{})

export const companyReducer = createReducer(
  initialState,
  on(CompanyActions.loadingCompany, (state, { loading }) => {
    return { ...state, loading: loading }
  }),
  on(CompanyActions.selectCompany, (state, { id }) => {
    return { ...state, selectCompanyId: id }
  }),
  on(CompanyActions.mapCompany, (state, { entityMap }) => {
    return adapter.mapOne(entityMap, state)
  }),
  on(CompanyActions.updateCompany, (state, { update }) => {
    return adapter.updateOne(update, state)
  }),
  on(CompanyActions.mapCompanies, (state, { entityMap }) => {
    return adapter.map(entityMap, state)
  }),
  on(CompanyActions.loadCompanies, (state, { companies }) => {
    return adapter.setAll(companies, state)
  }),
  on(CompanyActions.setCompanies, (state, { companies }) => {
    return adapter.setMany(companies, state)
  }),
  on(CompanyActions.clearCompanies, state => {
    return adapter.removeAll({ ...state, selectCompanyId: null })
  }),
  on(CompanyActions.deleteCompany, (state, { id }) => {
    return adapter.removeOne(id, state)
  })
)

// get the selectors
export const {
  // select the array of companies
  selectAll: selectAllCompanies,
} = adapter.getSelectors()
