import { createAction, props } from '@ngrx/store'
import { EntityMap, EntityMapOne, Update } from '@ngrx/entity'
import { IPeriod } from '@modules/period/interfaces/period'

export const loadingPeriod = createAction('[Period/API] Loading Periods', props<{ loading: boolean }>())
export const loadPeriods = createAction('[Period/API] Load Periods', props<{ periods: IPeriod[] }>())
export const selectPeriod = createAction('[Users Page] Select Periods', props<{ id: string }>())
export const setPeriods = createAction('[Period/API] Set Periods', props<{ periods: IPeriod[] }>())
export const updatePeriod = createAction('[Period/API] Update Period', props<{ update: Update<IPeriod> }>())
export const mapPeriod = createAction('[Period/API] Map Period', props<{ entityMap: EntityMapOne<IPeriod> }>())
export const mapPeriods = createAction('[Period/API] Map Periods', props<{ entityMap: EntityMap<IPeriod> }>())
export const clearPeriods = createAction('[Period/API] Clear Periods')
