import { IWithdrawal } from '@modules/withdrawal/interfaces/withdrawal'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import * as WithdrawalActions from '../actions/withdrawal.actions'

export interface StateWithdrawal extends EntityState<IWithdrawal> {
  selectWithdrawalId?: string | number | null
}

export const adapter: EntityAdapter<IWithdrawal> = createEntityAdapter<IWithdrawal>()

export const initialState: StateWithdrawal = adapter.getInitialState({
  id: null,
  name: '',
  status: '',
  species: '',
  type: '',
  gender: '',
  origin: {
    name: '',
    url: '',
  },
  location: {
    name: '',
    url: '',
  },
  image: '',
  episode: [],
  url: '',
  created: '',
  selectWithdrawalId: null,
})

export const withdrawalReducer = createReducer(
  initialState,
  on(WithdrawalActions.loadingWithdrawal, (state, { loading }) => {
    return { ...state, loading: loading }
  }),
  on(WithdrawalActions.selectWithdrawal, (state, { id }) => {
    return { ...state, selectWithdrawalId: id }
  }),
  on(WithdrawalActions.setWithdrawals, (state, { withdrawals }) => {
    return adapter.setMany(withdrawals, state)
  }),
  on(WithdrawalActions.setWithdrawal, (state, { withdrawal }) => {
    return adapter.setOne(withdrawal, state)
  }),
  on(WithdrawalActions.upsertWithdrawal, (state, { withdrawal }) => {
    return adapter.upsertOne(withdrawal, state)
  }),
  on(WithdrawalActions.updateWithdrawal, (state, { update }) => {
    return adapter.updateOne(update, state)
  }),
  on(WithdrawalActions.updateWithdrawals, (state, { updates }) => {
    return adapter.updateMany(updates, state)
  }),
  on(WithdrawalActions.mapWithdrawal, (state, { entityMap }) => {
    return adapter.mapOne(entityMap, state)
  }),
  on(WithdrawalActions.mapWithdrawals, (state, { entityMap }) => {
    return adapter.map(entityMap, state)
  }),
  on(WithdrawalActions.deleteWithdrawal, (state, { id }) => {
    return adapter.removeOne(id, state)
  }),
  on(WithdrawalActions.deleteWithdrawals, (state, { ids }) => {
    return adapter.removeMany(ids, state)
  }),
  on(WithdrawalActions.loadWithdrawals, (state, { withdrawals }) => {
    return adapter.setAll(withdrawals, state)
  }),
  on(WithdrawalActions.clearWithdrawals, state => {
    return adapter.removeAll({ ...state, selectWithdrawalId: null })
  })
)
export const getSelectedWithdrawalId = (state: StateWithdrawal) => state.selectWithdrawalId

// get the selectors
export const {
  // select the array of withdrawal ids
  selectIds: selectWithdrawalIds,

  // select the dictionary of withdrawal entities
  selectEntities: selectWithdrawalEntities,

  // select the array of withdrawals
  selectAll: selectAllWithdrawals,

  // select the total withdrawal count
  selectTotal: selectWithdrawalTotal,
} = adapter.getSelectors()
