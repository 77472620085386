import { IPeriod } from '@modules/period/interfaces/period'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import * as PeriodActions from '../actions/period.actions'

export interface StatePeriod extends EntityState<IPeriod> {
  selectPeriodId?: string | number | null
  loading: boolean
}

export const adapter: EntityAdapter<IPeriod> = createEntityAdapter<IPeriod>()

export const initialState: StatePeriod = adapter.getInitialState(<StatePeriod>{})

export const periodReducer = createReducer(
  initialState,
  on(PeriodActions.loadingPeriod, (state, { loading }) => {
    return { ...state, loading: loading }
  }),
  on(PeriodActions.selectPeriod, (state, { id }) => {
    return { ...state, selectPeriodId: id }
  }),
  on(PeriodActions.mapPeriod, (state, { entityMap }) => {
    return adapter.mapOne(entityMap, state)
  }),
  on(PeriodActions.updatePeriod, (state, { update }) => {
    return adapter.updateOne(update, state)
  }),
  on(PeriodActions.mapPeriods, (state, { entityMap }) => {
    return adapter.map(entityMap, state)
  }),
  on(PeriodActions.loadPeriods, (state, { periods }) => {
    return adapter.setAll(periods, state)
  }),
  on(PeriodActions.setPeriods, (state, { periods }) => {
    return adapter.setMany(periods, state)
  }),
  on(PeriodActions.clearPeriods, state => {
    return adapter.removeAll({ ...state, selectPeriodId: null })
  })
)

// get the selectors
export const {
  // select the array of periods
  selectAll: selectAllPeriods,
} = adapter.getSelectors()
