import { Injectable } from '@angular/core'
import {
  selectAppCompanyCreditSettings,
  selectAppShowSectionFinance,
  selectAppShowSectionFinanceActive,
  selectAppUserIsAdmin,
  selectAppUserIsFinance,
  selectAppUserIsSuperAdmin,
  selectAppUserRole,
  selectAppUserRoleType,
} from '@shared/state/selectors/app.selectors'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { UserType } from '@modules/site/interfaces/site'
import { map } from 'rxjs/operators'
import { SessionService } from '@services/session.service'

@Injectable({ providedIn: 'root' })
export class PermissionService {
  public isAdmin$: Observable<boolean>
  public role$: Observable<UserType>
  public role: UserType | null = null
  public isSuperAdmin$: Observable<boolean>
  public isFunder$: Observable<boolean>
  public type$: Observable<UserType | undefined>
  public selectAppShowSectionFinanceActive$: Observable<boolean>
  public selectAppShowSectionFinance$: Observable<boolean>
  public appShowSectionFinance: boolean = false
  public selectAppShowDocument$: Observable<boolean>

  constructor(protected _store: Store, protected sessionService: SessionService) {
    this.isAdmin$ = this._store.select(selectAppUserIsAdmin)
    this.isSuperAdmin$ = this._store.select(selectAppUserIsSuperAdmin)
    this.isFunder$ = this._store.select(selectAppUserIsFinance)
    this.role$ = this._store.select(selectAppUserRole)
    this.type$ = this._store.select(selectAppUserRoleType)
    this.selectAppShowSectionFinanceActive$ = this._store.select(selectAppShowSectionFinanceActive)
    this.selectAppShowSectionFinance$ = this._store.select(selectAppShowSectionFinance)
    this.role$.subscribe(role => {
      this.role = role
    })
    this.selectAppShowSectionFinance$.subscribe(show => {
      this.appShowSectionFinance = show
    })
    this.selectAppShowDocument$ = this._store.select(selectAppCompanyCreditSettings).pipe(
      map(settings => {
        const roles = this.sessionService.getRoles()
        return !!(roles.includes(UserType.ADMIN) && settings?.['admin_can_approve_docs?'])
      })
    )
  }
}
