import { createAction, props } from '@ngrx/store'
import { EntityMap, EntityMapOne, Update } from '@ngrx/entity'
import { ICredit } from '@modules/credit/interfaces/credit'

export const loadingCredit = createAction('[Credit/API] Loading Credits', props<{ loading: boolean }>())
export const loadCredits = createAction('[Credit/API] Load Credits', props<{ credits: ICredit[] }>())
export const selectCredit = createAction('[Users Page] Select Credits', props<{ id: string }>())
export const setCredits = createAction('[Credit/API] Set Credits', props<{ credits: ICredit[] }>())
export const updateCredit = createAction('[Credit/API] Update Credit', props<{ update: Update<ICredit> }>())

export const deleteCredit = createAction('[Credit/API] Delete Credit', props<{ id: string }>())
export const mapCredit = createAction('[Credit/API] Map Credit', props<{ entityMap: EntityMapOne<ICredit> }>())
export const mapCredits = createAction('[Credit/API] Map Credits', props<{ entityMap: EntityMap<ICredit> }>())
export const clearCredits = createAction('[Credit/API] Clear Credits')
