import { Injectable } from '@angular/core'
import * as SHA1 from 'crypto-js/sha1'
import { v4 as uuidv4 } from 'uuid'

export enum EIdemPotencyType {
  UUID = 'UUID',
  SHA1 = 'SHA1',
}

export type IdemPotencyType = 'UUID' | 'SHA1'

@Injectable()
export class IdemPotencyService {
  private idempotencyKey: string | null = null

  get idemKey(): string | null {
    return this.idempotencyKey
  }

  set idemKey(key: string | null) {
    this.idempotencyKey = key
  }

  get idemKeyName(): string {
    return 'Idempotency-key'
  }

  generateIdem(type: IdemPotencyType, payload?: any): string | null {
    if (type == EIdemPotencyType.UUID) {
      this.idempotencyKey = uuidv4()
    } else if (type == EIdemPotencyType.SHA1) {
      this.idempotencyKey = SHA1(JSON.stringify(payload)).toString()
    }
    return this.idempotencyKey
  }
}
