import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import IconComponent from './icon.component'
import { CommonModule } from '@angular/common'
import IconService from '@atom/icon/icon.service'

@NgModule({
  declarations: [IconComponent],
  imports: [CommonModule, HttpClientModule],
  exports: [IconComponent],
  providers: [IconService],
})
export class IconModule {}
