import { Config as EasyConfig } from 'ngx-easy-table'
import { Columns as EasyColumns } from 'ngx-easy-table'
import { Event as EasyEvent } from 'ngx-easy-table'
export interface Config extends EasyConfig {}
export interface Columns extends EasyColumns {}
export const Event = {
  ...EasyEvent,
}

export type ColumnExtend = Record<string, Partial<Columns>>
