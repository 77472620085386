import { createFeatureSelector, createSelector } from '@ngrx/store'
import { StateSite } from '@modules/site/state/reducers/site.reducer'
import { IColumnsFilter, ISite, TModule } from '@modules/site/interfaces/site'
import { Columns } from '@molecule/table'

export const selectSiteFeature = createFeatureSelector<StateSite>('site')
export const selectSiteLoading = createSelector(selectSiteFeature, state => state.loading)
export const selectSite = createSelector(selectSiteFeature, state => state.site)
export const selectSiteModules = createSelector(selectSiteFeature, (state: StateSite) => {
  return state.site.map((module: ISite) => {
    return module.dashboard_module
  })
})

export const selectSiteModulesMenu = createSelector(selectSiteFeature, (state: StateSite) => {
  return (
    state?.site
      ?.map((module: ISite) => {
        return {
          ...module.dashboard_module,
        }
      })
      ?.sort(function (a, b) {
        return a.position - b.position
      }) || []
  )
})

export const selectSiteModulesCompanies = createSelector(selectSiteFeature, (state: StateSite) => {
  if (!state.site && state.site) {
    return undefined
  }

  if (state.site.length === 0) {
    return undefined
  }
  return state.site.some((module: ISite) => module?.dashboard_module.path === TModule.COMPANIES)
})
export const selectSiteModule = (moduleId: string | null = null) =>
  createSelector(selectSiteFeature, (state: StateSite) => {
    if (moduleId) {
      const module = state?.site?.find(x => [x.dashboard_module.path, x.dashboard_module.id].includes(moduleId))
      if (module) {
        return {
          ...module.dashboard_module,
          views: module.views,
        }
      } else {
        return null
      }
    }
    return state?.selectModule ?? null
  })

export const selectSiteViews = (moduleId: string | null = null) =>
  createSelector(selectSiteFeature, (state: StateSite) => {
    if (!state?.site) {
      return []
    }
    const module = state.site.find(m =>
      [m.dashboard_module.path, m.dashboard_module.id].includes(
        <string>(moduleId ? moduleId : state?.selectModule?.path)
      )
    )
    const ordered = module?.views
    return ordered || []
  })

export const selectSiteView = (moduleId: string | null = null, viewId: string | null = null) =>
  createSelector(selectSiteFeature, (state: StateSite) => {
    if (!moduleId) {
      return state?.selectView ?? null
    }

    const module = state.site.find(m => [m.dashboard_module.path, m.dashboard_module.id].includes(moduleId))
    const view = module?.views.find(v => v.id === viewId)
    return view ?? null
  })

export const selectSiteModuleColumns = createSelector(selectSiteFeature, (state: StateSite) => {
  const module = state.site.find(m => m.dashboard_module.id === state?.selectModule?.id)
  return module?.dashboard_module.columns || []
})

export const selectSiteModuleFilters = (moduleId: string | null = null) =>
  createSelector(selectSiteFeature, (state: StateSite) => {
    const module = state.site.find(m =>
      [m.dashboard_module.path, m.dashboard_module.id].includes(
        <string>(moduleId ? moduleId : state?.selectModule?.path)
      )
    )
    //filters
    const filters: IColumnsFilter = {}
    if (module?.dashboard_module.columns_filters) {
      for (const [key, value] of Object.entries(module?.dashboard_module.columns_filters)) {
        if (value.visible) {
          filters[key] = value
        }
      }
    }

    if (module?.dashboard_module.columns_filters) {
      for (const [key, value] of Object.entries(module?.dashboard_module.columns_filters)) {
        if (!value.visible) {
          filters[key] = value
        }
      }
    }

    return filters ?? null
  })

export const selectDefaultModuleView = (moduleId: string | null = null) =>
  createSelector(selectSiteFeature, (state: StateSite) => {
    const module = state.site.find(m =>
      [m.dashboard_module.path, m.dashboard_module.id].includes(
        <string>(moduleId ? moduleId : state?.selectModule?.path)
      )
    )
    return module?.views.find(view => view.is_default) || null
  })

export const selectSiteModuleColumnsTable = (
  columnsExtend: Record<string, Partial<Columns>>,
  moduleId: string | null = null
) =>
  createSelector(selectSiteFeature, (state: StateSite): Columns[] => {
    const module = state.site.find(m =>
      [m.dashboard_module.path, m.dashboard_module.id].includes(
        <string>(moduleId ? moduleId : state?.selectModule?.path)
      )
    )
    let columns = module?.dashboard_module.columns || {}

    if (Object.keys(state?.selectView?.columns || {}).length > 0 && state?.selectView?.columns) {
      columns = state?.selectView?.columns
    }

    const columnsTable: Columns[] = []
    for (const [key, value] of Object.entries(columns)) {
      if (value.visible === false) {
        continue
      }
      columnsTable.push({
        key: key,
        title: value?.display_name,
        orderEnabled: value?.sort,
        ...columnsExtend[key],
      })
    }
    return columnsTable
  })

export const selectSiteColumnsFilter = createSelector(selectSiteFeature, (state: StateSite) => {
  const module = state.site.find(m => m.dashboard_module.id === state?.selectModule?.id)
  return module?.dashboard_module.columns_filters || []
})
