import { createAction, props } from '@ngrx/store'
import { EntityMap, EntityMapOne, Update } from '@ngrx/entity'
import { ICollaborator } from '@modules/collaborator/interfaces/collaborator'

export const loadingCollaborator = createAction(
  '[Collaborator/API] Loading Collaborators',
  props<{ loading: boolean }>()
)
export const loadCollaborators = createAction(
  '[Collaborator/API] Load Collaborators',
  props<{ collaborators: ICollaborator[] }>()
)
export const selectCollaborator = createAction('[Users Page] Select Collaborators', props<{ id: string }>())
export const setCollaborators = createAction(
  '[Collaborator/API] Set Collaborators',
  props<{ collaborators: ICollaborator[] }>()
)
export const updateCollaborator = createAction(
  '[Collaborator/API] Update Collaborator',
  props<{ update: Update<ICollaborator> }>()
)

export const deleteCollaborator = createAction('[Collaborator/API] Delete Collaborator', props<{ id: string }>())
export const mapCollaborator = createAction(
  '[Collaborator/API] Map Collaborator',
  props<{ entityMap: EntityMapOne<ICollaborator> }>()
)
export const mapCollaborators = createAction(
  '[Collaborator/API] Map Collaborators',
  props<{ entityMap: EntityMap<ICollaborator> }>()
)
export const clearCollaborators = createAction('[Collaborator/API] Clear Collaborators')
