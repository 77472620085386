import { ChangeDetectorRef, Directive, Inject, Injector, Input, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Params } from '@angular/router'
import { Store } from '@ngrx/store'
import { delay, distinctUntilChanged, finalize, Observable, Subject, take, takeUntil } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import { BaseResource } from '@shared/resources/base.resource'
import { OnState } from '@shared/interfaces/state'
import { IPagination, PaginationService } from '@services/list/pagination.service'
import { TableService } from '@services/list/table.service'
import { deleteView, selectView } from '@modules/site/state/actions/site.actions'
import { IStatePageQuery, UrlListStateService } from '@services/list/url-list-state.service'
import { IColumnsFilter, IModule, IModuleView } from '@modules/site/interfaces/site'
import { checkAdminParams, cleanObject } from '@services/helpers'
import { ColumnExtend, Columns, Event } from '@molecule/table'
import {
  selectDefaultModuleView,
  selectSiteModule,
  selectSiteModuleColumnsTable,
  selectSiteModuleFilters,
  selectSiteView,
  selectSiteViews,
} from '@modules/site/state/selectors/site.selectors'

import { isEmpty } from 'lodash-es'
import { IResponseList } from '@shared/interfaces/global'
import { selectAppCompany, selectAppUser } from '@shared/state/selectors/app.selectors'
import { ICompany } from '@modules/company/interfaces/company'
import { SessionService } from '@services/session.service'
import { IUser } from '@modules/configuration/interfaces/configuration'
import { Actions, ofType } from '@ngrx/effects'
import { PermissionService } from '@services/permission.service'
import { isEqual } from 'lodash-es'
@Directive()
export class BaseListService<T> implements OnDestroy, OnInit {
  /**
   * Selected all
   * @type {boolean}
   */
  public allSelected = false

  /**
   * Columns for table
   * @type {Columns[]}
   */
  public columns!: Columns[]

  /**
   * Set of entities selecteds
   * @type {Set}
   */
  public selected = new Set()

  /**
   * Meta data recovery from service
   * @type {any}
   */
  public meta: any = {}

  /**
   * Preserve query params for recovery data pagination when refresh page
   * @type {boolean}
   */
  @Input() preserveQueryParams: boolean = true

  /**
   * Filters for apply in list
   * @type {Record<string, any> | null}
   */
  @Input() public filters: Record<string, any> | null = null

  /**
   * Filter apply extras add to params for send to service
   * @type {Record<string, any> | null}
   */
  @Input() public filtersExtras: Record<string, any> | null = null

  /**
   * Order current in table
   * @type {Record<string, any> | null}
   */
  @Input() public order: Record<string, any> | null = null

  /**
   * View id for query in service api
   * @type {string | null}
   */
  @Input() public viewId: string | null = null

  /**
   * Module id current
   * @type {string | null}
   */
  @Input() public moduleId: string | null = null

  /**
   * Disable filter when include component in other component
   * @type {boolean}
   */
  @Input() public disableFilters: boolean = false

  /**
   * Columns custom for table
   * @type {ColumnExtend}
   */
  @Input() columnsExtends: ColumnExtend = {}

  /**
   * Name method for recovery list inside resource
   * @type {string}
   */
  @Input() queryMethod: keyof BaseResource | string = 'query'

  protected _state!: OnState<T>
  protected _route!: ActivatedRoute
  protected _unsubscribeAll: Subject<any> = new Subject<any>()
  protected _resource!: BaseResource | any
  protected _store: Store

  public pagination!: PaginationService
  public table!: TableService
  public urlListState!: UrlListStateService

  entities$!: Observable<Array<T & { id: any }>>

  _cdr: ChangeDetectorRef

  public user$!: Observable<IUser | null>
  public view$!: Observable<IModuleView | null>
  public views$!: Observable<IModuleView[]>
  public module$!: Observable<IModule | null>
  public filters$!: Observable<IColumnsFilter | null>
  public company$!: Observable<ICompany>
  public company!: ICompany | null
  protected updates$!: Actions

  protected _sessionService!: SessionService
  public permission: PermissionService

  constructor(@Inject(Injector) private injector: Injector) {
    this.pagination = injector.get(PaginationService)
    this._cdr = injector.get(ChangeDetectorRef)
    this.table = injector.get(TableService)
    this.permission = injector.get(PermissionService)

    this.updates$ = injector.get(Actions)
    this.urlListState = injector.get(UrlListStateService)
    this._route = injector.get(ActivatedRoute)
    this._store = injector.get(Store)
    this._sessionService = injector.get(SessionService)
    this.table.setConfig('detailsTemplate', false)
    this.table.setConfig('isLoading', true)

    this.urlListState.queryParams().subscribe((params: Params) => {
      if (!this.preserveQueryParams) {
        return
      }
      const { page, limit, offset, viewId } = params
      //recovery viewId
      if (viewId) {
        this.viewId = viewId
      }

      //recovery pagination
      if (limit) {
        this.pagination.pagination = {
          limit: +limit,
          page: +page,
          offset: +offset,
          count: 0,
        }
      }
    })

    //subscribe view for dispatch list
  }

  getSelectColumns(): Observable<Columns[]> {
    return this._store.select(selectSiteModuleColumnsTable(this.columnsExtends, this.moduleId))
  }

  /**
   * Get list
   */
  ngOnInit(): void {
    this.recoveryState()
  }

  defineState(): void {
    this.views$ = this._store.select(selectSiteViews(this.moduleId))
    this.company$ = this._store.select(selectAppCompany)
    this.view$ = this._store.select(selectSiteView(this.moduleId, this.viewId))
    this.module$ = this._store.select(selectSiteModule(this.moduleId))
    this.user$ = this._store.select(selectAppUser)
    this.filters$ = this._store.select(selectSiteModuleFilters(this.moduleId))
    this.updates$.pipe(ofType(deleteView), takeUntil(this._unsubscribeAll)).subscribe(() => {
      this._store
        .select(selectDefaultModuleView(this.moduleId))
        .pipe(take(1))
        .subscribe((view: IModuleView | null) => {
          if (view) {
            this.onSelectView(view)
          }
        })
    })
  }

  recoveryStateView(): void {
    this.company$
      .pipe(
        takeUntil(this._unsubscribeAll),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      )
      .subscribe(company => {
        this.company = company
      })
    this.module$
      .pipe(
        takeUntil(this._unsubscribeAll),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      )
      .subscribe(module => {
        if (!module) {
          return
        }
        //verify views
        this.views$.pipe(take(1)).subscribe((views: IModuleView[]) => {
          this.view$.pipe(take(1)).subscribe((view: IModuleView | null) => {
            if (view) {
              return
            }
            if (!this.viewId) {
              //load first views
              this.onSelectView(views[0])
            } else {
              //if not view recovereds
              this.onSelectView({
                id: this.viewId,
              })
            }
          })
        })
      })
  }

  recoveryState() {
    this.defineState()
    this.recoveryStateView()
    //when module is load
    setTimeout(() => {
      this.view$
        .pipe(takeUntil(this._unsubscribeAll), delay(300), distinctUntilChanged())
        .subscribe((view: IModuleView | null) => {
          if (view) {
            this.viewId = view.id
            this.filters = view.items
            this.filters = {
              ...this.filters,
            }
            this.pagination.reset()
            this.getList().subscribe()
          } else {
            this.viewId = null
          }
        })
    })
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true)
    this._unsubscribeAll.complete()
  }

  /**
   * Search with params
   *
   * @param filters
   */
  public onSearch(filters = {}): void {
    this.filters = {
      ...this.filters,
      ...filters,
    }
    this.pagination.reset()
    this.getList().subscribe()
  }

  parseParams(): Record<string, any> {
    const { offset, limit } = this.pagination.pagination
    let params: any = {
      offset,
      limit,
      ...(this.order ? { order: this.order } : null),
      ...(this.viewId ? { view_id: this.viewId } : null),
      company_id: checkAdminParams(this._sessionService.getRoles(), this.company?.menu_type) ? this.company?.id : null,
    }

    const items = cleanObject({
      ...(this.filters || {}),
      ...(this.filtersExtras || {}),
    })

    if (Object.keys(items).length > 0) {
      params = { ...params, items }
    }

    return cleanObject(params)
  }

  /**
   * Get data from service
   */
  getList(): Observable<any> {
    this.selected = new Set()
    this.table.setConfig('isLoading', true)
    this._state?.loadEntities([])
    const params = this.parseParams()
    return this._resource[this.queryMethod](params).pipe(
      finalize(() => {
        this.table.setConfig('isLoading', false)
      }),
      map((response: IResponseList<T>) => {
        let { count, limit, data, meta } = response

        if (isEmpty(response)) {
          this._state?.loadEntities([])
          this.pagination.reset()
          return response
        }
        //map list
        data = this.mapList(data)
        this.meta = meta
        const page = this.pagination.pagination.page
        this.table.setConfig('rows', +data?.length)
        this._state?.loadEntities(data)
        this.pagination.pagination = {
          count: +count,
          limit: +limit,
          offset: this.pagination.getOffset(page, limit),
          page: page,
        }

        this.setQueryParams()
        return response
      }),

      catchError(e => {
        this.table.setConfig('isLoading', false)
        this._state?.loadEntities([])
        return e
      })
    )
  }

  /**
   *
   * @param {T[]} list
   * @returns {T[]}
   */
  mapList(list: T[]): T[] {
    return list
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item?.id || item?.key || index
  }

  /**
   * Set params in query params for no lost navigation un pagination on refresh page
   *
   * @param params
   */
  setQueryParams() {
    if (!this.preserveQueryParams) {
      return
    }
    this.urlListState.urlState = <IStatePageQuery>cleanObject({
      offset: this.pagination.pagination.offset,
      limit: this.pagination.pagination.limit,
      page: this.pagination.pagination.page,
      viewId: this.viewId,
    })
    this.urlListState.setQueryParams()
  }

  /**
   * Event for change pagination and update an request new list
   *
   * @param paginator
   */
  onPageChanged(paginator: Partial<IPagination>): void {
    if (paginator.limit !== this.pagination.pagination.limit) {
      this.pagination.onPageChanged({
        page: 1,
        limit: paginator.limit,
      })
    } else {
      this.pagination.onPageChanged(paginator)
    }
    this.getList().subscribe()
  }

  /**
   * Check if row is selected
   * @param identifier
   * @returns {boolean}
   */
  isSelect(identifier: any): boolean {
    return this.selected.has(identifier)
  }

  /**
   * Event for select row
   * @param {T} entity
   */
  onSelectCheck(entity: T): void {
    this.onEventEmitted({
      event: Event.onCheckboxSelect,
      value: {
        row: entity,
      },
    })
  }

  /**
   * Event for change sort and update and request new list
   * @param {{event: string, value: any}} $event
   */
  onEventEmitted($event: { event: string; value: any }): void {
    switch ($event.event) {
      case Event.onCheckboxSelect:
        //if select one entity add to var selected
        if (this.selected.has($event.value.row.id)) {
          this.selected.delete($event.value.row.id)
        } else {
          this.selected.add($event.value.row.id)
        }
        break
      case Event.onSelectAll:
        //if select all entities add to var selected or removed all
        this.selectAll()
        break
      case Event.onOrder:
        //recovery and armed var for order
        this.order = null
        if ($event?.value?.key && $event?.value?.order) {
          this.order = {
            by: $event?.value?.key,
            direction: $event?.value?.order,
          }
        }
        //dispatch search
        this.onSearch()
        break
    }
  }

  selectAll() {
    this.entities$.pipe(take(1)).subscribe(entities => {
      this.allSelected = !!!this.allSelected
      entities.forEach(entity => {
        if (!this.allSelected) {
          this.selected.delete(entity.id)
        } else {
          this.selected.add(entity.id)
        }
      })
    })
  }

  /**
   * On select view
   * @param {IModuleView} view
   */
  onSelectView(view: Partial<IModuleView>): void {
    this._store.dispatch(selectView({ selectView: view }))
    this.viewId = view?.id || null
  }

  convertToParam(items: any): any {
    let newItems: any = {}
    if (!items) {
      return newItems
    }
    Object.keys(items).forEach((key: string) => {
      const itemKey: string = `items[${key}]`
      newItems[itemKey] = items[key]
    })
    return newItems
  }

  onDownload(): void {
    const params: any = cleanObject({
      ...(this.viewId ? { view_id: this.viewId } : null),
      ...cleanObject(this.convertToParam(this.filters!)),
      ...this.filtersExtras,
      company_id: checkAdminParams(this._sessionService.getRoles(), this.company?.menu_type) ? this.company?.id : null,
    })
    this._state?.loading(true)

    this._resource.report(params).subscribe((response: any) => {
      this._state?.loading(false)
      this.module$.pipe(take(1)).subscribe(module => {
        const blob = new Blob([response])
        const element = window.document.createElement('a')
        element.href = window.URL.createObjectURL(blob)
        element.download = `${module?.display_name}.xlsx`
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
      })
    })
  }

  disableActions(): void {
    this.permission.isFunder$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isFunder: boolean) => {
      if (isFunder) {
        this.table.setConfig('checkboxes', false)
        this.table.setConfig('additionalActions', false)
      }
    })
  }
}
