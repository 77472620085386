import { createAction, props } from '@ngrx/store'
import { EntityMap, EntityMapOne, Update } from '@ngrx/entity'
import { ICompany } from '@modules/company/interfaces/company'

export const loadingCompany = createAction('[Company/API] Loading Companies', props<{ loading: boolean }>())
export const loadCompanies = createAction('[Company/API] Load Companies', props<{ companies: ICompany[] }>())
export const selectCompany = createAction('[Users Page] Select Companies', props<{ id: string }>())
export const setCompanies = createAction('[Company/API] Set Companies', props<{ companies: ICompany[] }>())
export const updateCompany = createAction('[Company/API] Update Company', props<{ update: Update<ICompany> }>())
export const deleteCompany = createAction('[Company/API] Delete Company', props<{ id: string }>())
export const mapCompany = createAction('[Company/API] Map Company', props<{ entityMap: EntityMapOne<ICompany> }>())
export const mapCompanies = createAction('[Company/API] Map Companies', props<{ entityMap: EntityMap<ICompany> }>())
export const clearCompanies = createAction('[Company/API] Clear Companies')
