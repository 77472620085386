import { createAction, props } from '@ngrx/store'
import { EntityMap, EntityMapOne, Update } from '@ngrx/entity'
import { IVariableIncomeBatch } from '../../interfaces/variable-income'

export const loadingVariableIncomeBatch = createAction(
  '[Variable Income Batch/API] Loading Variable Income Batches',
  props<{ loading: boolean }>()
)
export const loadVariableIncomeBatches = createAction(
  '[Variable Income Batch/API] Load Variable Income Batches',
  props<{ variableIncomeBatches: IVariableIncomeBatch[] }>()
)
export const selectVariableIncomeBatch = createAction(
  '[Users Page] Select Variable Income Batches',
  props<{ id: string }>()
)
export const setVariableIncomeBatches = createAction(
  '[Variable Income Batch/API] Set Variable Income Batches',
  props<{ variableIncomeBatches: IVariableIncomeBatch[] }>()
)
export const updateVariableIncomeBatch = createAction(
  '[Variable Income Batch/API] Update Variable Income Batch',
  props<{ update: Update<IVariableIncomeBatch> }>()
)
export const mapVariableIncomeBatch = createAction(
  '[Variable Income Batch/API] Map Variable Income Batch',
  props<{ entityMap: EntityMapOne<IVariableIncomeBatch> }>()
)
export const mapVariableIncomeBatches = createAction(
  '[Variable Income Batch/API] Map Variable Income Batches',
  props<{ entityMap: EntityMap<IVariableIncomeBatch> }>()
)
export const clearVariableIncomeBatches = createAction('[Variable Income Batch/API] Clear Variable Income Batches')
