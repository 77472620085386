import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  QueryList,
  Renderer2,
  Type,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from '@angular/core'
import { ModalConfig } from '@molecule/modal/config/modal.config'
import { ModalService } from '@molecule/modal/services/modal.service'

@Component({
  selector: 'monto-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public closable: boolean = true
  @Input() public escapable: boolean = false
  @Input() public dismissible: boolean = false
  @Input() public identifier: string = ''
  @Input() public customClass: string = 'dialog-animation-fade'
  @Input() public visible: boolean = false
  @Input() public backdrop: boolean = true
  @Input() public force: boolean = true
  @Input() public hideDelay: number = 0
  @Input() public autostart: boolean = false
  @Input() public target: string = ''
  @Input() public ariaLabel: string | null = null
  @Input() public ariaLabelledBy: string | null = null
  @Input() public ariaDescribedBy: string | null = null

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onDismiss: EventEmitter<any> = new EventEmitter()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onClose: EventEmitter<any> = new EventEmitter()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onOpen: EventEmitter<any> = new EventEmitter()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onCreate: EventEmitter<any> = new EventEmitter()

  @HostBinding('class.open') get checkOpen() {
    return this.visible
  }

  public contentComponent!: Type<Component>
  public layerPosition: number = 1041
  public overlayVisible: boolean = false
  public openedClass: boolean = false

  public createFrom = 'html'

  private _data: any
  private _componentRef!: ComponentRef<any>

  @ViewChildren('content') private content!: QueryList<ElementRef>
  @ViewChildren('dialog') public dialog!: QueryList<ElementRef>
  @ViewChildren('overlay') private overlay!: QueryList<ElementRef>
  @ViewChild('dynamicContent', { read: ViewContainerRef }) private dynamicContentContainer!: ViewContainerRef

  constructor(
    private _renderer: Renderer2,
    private _changeDetectorRef: ChangeDetectorRef,
    private _modalService: ModalService,
    private componentFactoryResolver: ComponentFactoryResolver,
    public readonly elementRef: ElementRef,
    @Inject(DOCUMENT) private _document: any,
    @Inject(PLATFORM_ID) private _platformId: any
  ) {}

  public ngOnInit(): void {
    this._sendEvent('create')
  }

  public ngAfterViewInit(): void {
    if (this.contentComponent) {
      const factory = this.componentFactoryResolver.resolveComponentFactory(this.contentComponent)
      this.createDynamicContent(this.dynamicContentContainer, factory)
    }
  }

  public ngOnDestroy(): void {
    this._sendEvent('delete')
  }

  /**
   * Open the modal instance
   *
   * @param top open the modal top of all other
   * @returns the modal component
   */
  public open(top?: boolean): ModalComponent {
    this._sendEvent('open', { top: top })
    return this
  }

  /**
   * Close the modal instance
   *
   * @returns the modal component
   */
  public close(): ModalComponent {
    this._sendEvent('close')
    this._sendEvent('delete')

    return this
  }

  /**
   * Dismiss the modal instance
   *
   * @param e the event sent by the browser
   * @returns the modal component
   */
  public dismiss(e: any): ModalComponent {
    if (!this.dismissible || !e.target.classList.contains('overlay')) {
      return this
    }

    this._sendEvent('dismiss')

    return this
  }

  /**
   * Toggle visibility of the modal instance
   *
   * @param top open the modal top of all other
   * @returns the modal component
   */
  public toggle(top?: boolean): ModalComponent {
    this._sendEvent('toggle', { top: top })

    return this
  }

  /**
   * Add a custom class to the modal instance
   *
   * @param className the class to add
   * @returns the modal component
   */
  public addCustomClass(className: string): ModalComponent {
    if (!this.customClass.length) {
      this.customClass = className
    } else {
      this.customClass += ' ' + className
    }

    return this
  }

  /**
   * Remove a custom class to the modal instance
   *
   * @param className the class to remove
   * @returns the modal component
   */
  public removeCustomClass(className?: string): ModalComponent {
    if (className) {
      this.customClass = this.customClass.replace(className, '').trim()
    } else {
      this.customClass = ''
    }

    return this
  }

  /**
   * Returns the visibility state of the modal instance
   */
  public isVisible(): boolean {
    return this.visible
  }

  /**
   * Add body class modal opened
   *
   * @returns the modal component
   */
  public addBodyClass(): ModalComponent {
    this._renderer.addClass(this._document.body, ModalConfig.bodyClassOpen)

    return this
  }

  /**
   * Add body class modal opened
   *
   * @returns the modal component
   */
  public removeBodyClass(): ModalComponent {
    this._renderer.removeClass(this._document.body, ModalConfig.bodyClassOpen)

    return this
  }

  public markForCheck() {
    try {
      this._changeDetectorRef.detectChanges()
    } catch (e) {}

    this._changeDetectorRef.markForCheck()
  }

  /**
   * Listens for window resize event and recalculates modal instance position if it is element-relative
   */
  @HostListener('window:resize')
  public targetPlacement(): boolean | void {
    if (!this.isBrowser || !this.dialog.length || !this.content.length || !this.overlay.length || !this.target) {
      return false
    }
    const targetElement = this._document.querySelector(this.target)

    if (!targetElement) {
      return false
    }

    const targetElementRect = targetElement.getBoundingClientRect()
    const bodyRect = this.overlay.first.nativeElement.getBoundingClientRect()

    const contentRect = this.content.first.nativeElement.getBoundingClientRect()
    const dialogRect = this.dialog.first.nativeElement.getBoundingClientRect()

    const marginLeft = parseInt(getComputedStyle(this.content.first.nativeElement).marginLeft as any, 10)
    const marginTop = parseInt(getComputedStyle(this.content.first.nativeElement).marginTop as any, 10)

    let offsetTop = targetElementRect.top - dialogRect.top - (contentRect.height - targetElementRect.height) / 2
    let offsetLeft = targetElementRect.left - dialogRect.left - (contentRect.width - targetElementRect.width) / 2

    if (offsetLeft + dialogRect.left + contentRect.width + marginLeft * 2 > bodyRect.width) {
      offsetLeft = bodyRect.width - (dialogRect.left + contentRect.width) - marginLeft * 2
    } else if (offsetLeft + dialogRect.left < 0) {
      offsetLeft = -dialogRect.left
    }

    if (offsetTop + dialogRect.top + contentRect.height + marginTop > bodyRect.height) {
      offsetTop = bodyRect.height - (dialogRect.top + contentRect.height) - marginTop
    }

    this._renderer.setStyle(this.content.first.nativeElement, 'top', (offsetTop < 0 ? 0 : offsetTop) + 'px')
    this._renderer.setStyle(this.content.first.nativeElement, 'left', offsetLeft + 'px')
  }

  private _sendEvent(name: string, extraData?: any): boolean {
    if (!this.isBrowser) {
      return false
    }

    const data = {
      extraData: extraData,
      instance: { id: this.identifier ?? 'main', modal: this },
    }

    const event = new CustomEvent(ModalConfig.prefixEvent + name, { detail: data })

    return window.dispatchEvent(event)
  }

  /**
   * Is current platform browser
   */
  private get isBrowser(): boolean {
    return isPlatformBrowser(this._platformId)
  }

  /**
   * Creates content inside provided ViewContainerRef
   */
  private createDynamicContent(viewContainerRef: ViewContainerRef, factory: ComponentFactory<Component>): void {
    viewContainerRef.clear()
    this._componentRef = viewContainerRef.createComponent(factory)
    this.assignModalDataToComponentData(this._componentRef)
    this.markForCheck()
  }

  /**
   * Assigns the modal data to the ComponentRef instance properties
   */
  private assignModalDataToComponentData(componentRef: ComponentRef<any>): void {
    if (componentRef) {
      Object.assign(componentRef.instance, this._data)
    }
  }

  /**
   * Assigns the ComponentRef instance properties to the modal data object
   */
  private assignComponentDataToModalData(componentRef: ComponentRef<any>): void {
    if (componentRef) {
      Object.assign(this._data, componentRef.instance)
    }
  }

  /**
   * Attach data to the modal instance
   *
   * @param data the data to attach
   * @param force override potentially attached data
   * @returns the modal component
   */
  public setData(data: any): ModalComponent {
    this._data = data
    this.assignModalDataToComponentData(this._componentRef)
    this.markForCheck()
    return this
  }
}
