import { IVariableIncomeBatch } from './../../interfaces/variable-income'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import * as VariableIncomeBatchActions from '../actions/variable-income-batch.actions'

export interface StateVariableIncomeBatch extends EntityState<IVariableIncomeBatch> {
  selectVariableIncomeBatchId?: string | number | null
  loading: boolean
}

export const adapter: EntityAdapter<IVariableIncomeBatch> = createEntityAdapter<IVariableIncomeBatch>()

export const initialState: StateVariableIncomeBatch = adapter.getInitialState(<StateVariableIncomeBatch>{
  loading: false,
})

export const variableIncomeBatchReducer = createReducer(
  initialState,
  on(VariableIncomeBatchActions.loadingVariableIncomeBatch, (state, { loading }) => {
    return { ...state, loading: loading }
  }),
  on(VariableIncomeBatchActions.selectVariableIncomeBatch, (state, { id }) => {
    return { ...state, selectVariableIncomeBatchId: id }
  }),
  on(VariableIncomeBatchActions.mapVariableIncomeBatch, (state, { entityMap }) => {
    return adapter.mapOne(entityMap, state)
  }),
  on(VariableIncomeBatchActions.updateVariableIncomeBatch, (state, { update }) => {
    return adapter.updateOne(update, state)
  }),
  on(VariableIncomeBatchActions.mapVariableIncomeBatches, (state, { entityMap }) => {
    return adapter.map(entityMap, state)
  }),
  on(VariableIncomeBatchActions.loadVariableIncomeBatches, (state, { variableIncomeBatches }) => {
    return adapter.setAll(variableIncomeBatches, state)
  }),
  on(VariableIncomeBatchActions.setVariableIncomeBatches, (state, { variableIncomeBatches }) => {
    return adapter.setMany(variableIncomeBatches, state)
  }),
  on(VariableIncomeBatchActions.clearVariableIncomeBatches, state => {
    return adapter.removeAll({ ...state, selectVariableIncomeBatchId: null })
  })
)

// get the selectors
export const {
  // select the array of variable income batches
  selectAll: selectAllVariableIncomeBatches,
} = adapter.getSelectors()
