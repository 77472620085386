import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import * as Sentry from '@sentry/angular'
import { BrowserTracing } from '@sentry/tracing'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'
import * as moment from 'moment-timezone'
import 'moment/locale/es' // without this line it didn't work
moment.locale('es')

if (environment.production) {
  Sentry.init({
    environment: environment.production ? 'production' : 'development',
    dsn: 'https://85742a1dd9f64ddd8cbb799ad741440e@o867048.ingest.sentry.io/4503965693181952',
    integrations: [
      new BrowserTracing({
        tracingOrigins: ['localhost', 'https://dev-api.monto.mx', 'http://dev-admin.monto.mx'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })

  enableProdMode()
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch(err => console.error(err))
