import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
declare var window: any
@Injectable({ providedIn: 'root' })
export default class IconService {
  private _icon: BehaviorSubject<Record<string, any>> = new BehaviorSubject({})

  constructor() {
    //recovery global icons
    window['globalIcon'] = {
      ...window['globalIcon'],
    }
    window['loadedIconsGlobal'] = {
      ...window['loadedIconsGlobal'],
    }

    //dispatch global icons
    this._icon.next({ ...window['globalIcon'] })
  }

  /**
   * Set icon merge with global icons
   * @param value
   */
  set icon(value: Record<string, any>) {
    window['globalIcon'] = {
      ...window['globalIcon'],
      ...value,
    }
    this._icon.next({ ...window['globalIcon'] })
  }

  /**
   * Getter observable icons
   */
  get icon$(): Observable<Record<string, any>> {
    return this._icon.asObservable()
  }

  /**
   * Get icon if exists
   * @param name
   */
  getIcon(name: string) {
    return window['loadedIconsGlobal'][name]
  }

  /**
   * Set icon
   * @param name
   * @param svg
   */
  setIcon(name: string, svg: any) {
    window['loadedIconsGlobal'][name] = svg
  }
}
