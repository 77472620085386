<div  *ngIf="overlayVisible" class="backdrop fixed inset-0 bg-black bg-opacity-70 transition-opacity"
      [ngClass]="{'transparent':!backdrop, 'overlay':true, 'overlay-open':openedClass}"
      [style.z-index]="visible ? layerPosition-1 : -1" (click)="dismiss($event)" #overlay></div>
<div [style.z-index]="visible ? layerPosition : -1"
     class="w-fit"
     [ngClass]="['dialog', customClass, openedClass ? 'dialog-open': 'dialog-close']" #dialog
     [attr.aria-hidden]="openedClass ? false : true"
     [attr.aria-label]="ariaLabel"
     [attr.aria-labelledby]="ariaLabelledBy"
     [attr.aria-describedby]="ariaDescribedBy">
<div class="modal relative w-fit" aria-labelledby="modal-title" role="dialog" aria-modal="true"
>

  <div class="fixed inset-0 overflow-y-auto">
    <div class="flex items-end items-center justify-center min-h-full text-center">
      <div class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all content"  #content>
        <div class="px-4 pt-4">
          <div class="flex justify-end"><monto-icon class="cursor-pointer" (click)="close()" name="times"></monto-icon></div>
        </div>
        <div class="px-3.5 py-3.5 overflow-x-auto">
          <ng-template #dynamicContent></ng-template>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
