export interface IColumnFilter {
  isLoading: boolean
  display_name?: string
  path?: string
  sort: boolean
  type: string
  value?: any | any[]
  visible: boolean
  placeholder?: string
}
export interface IColumn {
  display_name: string
  position: number
  sort: boolean
  visible: boolean
  visible_export: boolean
  visible_to_admin?: boolean
}
export interface IColumns {
  [key: string]: IColumn
}
export interface IModuleView {
  created_at: Date
  dashboard_module_id: string
  display_name: string
  id: string
  is_active: boolean
  active: boolean
  is_default: boolean
  items: Record<any, any>
  module_view_default_id?: null
  name: string
  position: number
  query: null | string
  row_details: boolean | null
  rows_per_page: number | null
  toggle_activation: boolean | null
  updated_at: Date
  columns: IColumns | null
  user_id?: string
}

export interface IColumnsFilter {
  [key: string]: IColumnFilter
}

export enum UserType {
  BOTH = 'both',
  SUPERADMIN = 'superadmin',
  ADMIN = 'admin',
  FINANCE_ADMIN = 'finance_admin',
  SAAS_SUPERADMIN = 'saas_superadmin',
}

export enum MenuType {
  MENU = 'menu',
  SUBMENU = 'submenu',
  EITHER = 'either',
}

export interface IDashboardModule {
  alt_text: null
  columns: IColumns | null
  columns_filters: IColumnsFilter | null
  constructor: null
  created_at: Date
  display_name: string
  icon: null | string
  id: string
  is_active: boolean
  model: null
  name: string
  path: string
  path_query: null | string
  position: number
  site: number
  updated_at: Date
  user_type: UserType
  menu_type: MenuType
  query_params?: any
}

export interface ISite {
  dashboard_module: IDashboardModule
  views: IModuleView[]
}

export interface IModule extends IDashboardModule {
  views: IModuleView[]
}

export enum TModule {
  WITHDRAWALS = '/withdrawal',
  COLLABORATOR = '/collaborator',
  FOLIOS = '/deduction',
  GROUPS = '/group',
  CREDITS = '/credit',
  SHOP = '/shop',
  COMPANIES = '/company/list',
  PERIOD = '/payroll_period',
  SETTING = '/setting',
  VARIABLE_INCOME = '/variable_income_batch',
  CREDIT = '/credit',
}

export enum TModuleViews {
  VARIABLE_INCOME_DETAIL = '32903199-765c-4b92-9d56-7153e24a3475',
  PERIOD = '0ffa8343-c53a-4322-b9ba-baa59daeddec',
  GROUP = '802cc519-09bc-4a9d-8d1d-66e6a315c61f',
  FOLIO = '8f2162db-c0be-4de6-bc91-df155d8ffec8',
  FOLIO_DELAYED = 'b3112581-c07e-4d20-ba43-9bf72cb01421',
  HISTORY = '9e5567ab-f164-4cea-9dcd-91997a941eca',
  INCIDENTS = '9e5567ab-f164-4cea-9dcd-91997a941eca',
  DEBT_FOLIOS_WITH_INCIDENTS = 'd52a8420-526f-4802-88e9-a975c7031f6e',
  PAYMENT_COLLECTION = '4485a88d-a2fb-40c7-a248-210600c3908b',
  FOLIO_INVOICES = '616703d0-df71-4e35-8500-b157cec04944',
}
