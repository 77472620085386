import { createAction, props } from '@ngrx/store'
import { EntityMap, EntityMapOne, Update } from '@ngrx/entity'
import { IShop } from '@modules/shop/interfaces/shop'

export const loadingShop = createAction('[Shop/API] Loading Shops', props<{ loading: boolean }>())
export const loadShops = createAction('[Shop/API] Load Shops', props<{ shops: IShop[] }>())
export const selectShop = createAction('[Users Page] Select Shops', props<{ id: string }>())
export const setShops = createAction('[Shop/API] Set Shops', props<{ shops: IShop[] }>())
export const updateShop = createAction('[Shop/API] Update Shop', props<{ update: Update<IShop> }>())

export const deleteShop = createAction('[Shop/API] Delete Shop', props<{ id: string }>())
export const mapShop = createAction('[Shop/API] Map Shop', props<{ entityMap: EntityMapOne<IShop> }>())
export const mapShops = createAction('[Shop/API] Map Shops', props<{ entityMap: EntityMap<IShop> }>())
export const clearShops = createAction('[Shop/API] Clear Shops')
