import { NgModule } from '@angular/core'

import IndicatorComponent from './indicator.component'
import { IconModule } from '@atom/icon/icon.module'
import { CommonModule } from '@angular/common'

@NgModule({
  declarations: [IndicatorComponent],
  imports: [CommonModule, IconModule],
  exports: [IndicatorComponent],
})
export class IndicatorModule {}
