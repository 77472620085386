import { Injectable } from '@angular/core'
import { BaseResource } from '@shared/resources/base.resource'
import {
  IResourceMethodObservable,
  ResourceAction,
  ResourceParams,
  ResourceRequestMethod,
  ResourceResponseBodyType,
} from '@ngx-resource/core'
import { ISite } from '@modules/site/interfaces/site'

@Injectable({ providedIn: 'root' })
@ResourceParams({
  pathPrefix: 'v3/site_tree',
})
export class SiteResource extends BaseResource {
  @ResourceAction({
    method: ResourceRequestMethod.Get,
    isArray: false,
    path: '/',
    responseBodyType: ResourceResponseBodyType.Json,
  })
  override query: IResourceMethodObservable<any, ISite[]> | any
}
