import { HttpClient } from '@angular/common/http'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core'
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'
import { filter, Subject, Subscription, takeUntil } from 'rxjs'
import { AtomColor, TNameIcon } from '@atom/shared/helper'
import IconService from '@atom/icon/icon.service'

@Component({
  selector: 'monto-icon',
  template: ` <div class="container-icon" [ngClass]="classes" [innerHtml]="src"></div>`,
  styleUrls: ['icon.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export default class IconComponent implements OnInit, OnDestroy {
  @Input() name!: TNameIcon
  @Input() size: 'small' | 'medium' | 'large' | 'full' = 'medium'
  protected src!: SafeResourceUrl | undefined
  @Input() color!: AtomColor | null
  protected _unsubscribeAll: Subject<any> = new Subject<any>()
  classes: string[] = []

  protected icon$!: Subscription

  @HostBinding('class')
  public get classesParent() {
    return this.classes.join(' ')
  }

  ngOnChanges(): void {
    this.classes = this.getClasses()
  }

  constructor(
    private _domSanitizer: DomSanitizer,
    private _httpClient: HttpClient,
    protected iconService: IconService,
    protected _changeDetectorRef: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.icon$ = this.iconService.icon$
      .pipe(
        takeUntil(this._unsubscribeAll),
        filter(icons => !!icons && !!icons[this.name] && !this.src)
      )
      .subscribe(icons => {
        if (icons[this.name]) {
          this.src = icons[this.name]
          this._changeDetectorRef.detectChanges()
        }
      })
    const url = 'icon-svgs/' + this.name + '.svg'
    if (this.iconService.getIcon(this.name)) {
      return
    }
    this.iconService.setIcon(this.name, true)
    if (typeof fetch !== 'undefined' && typeof document !== 'undefined') {
      fetch(url).then((rsp: any) => {
        if (rsp.ok) {
          return rsp.text().then((svg: string) => {
            this.src = this._domSanitizer.bypassSecurityTrustHtml(svg)
            this.iconService.icon = {
              [this.name]: this.src,
            }
            this._changeDetectorRef.detectChanges()
          })
        }
        return false
      })
    } else {
      this._httpClient
        .get(url, { responseType: 'text' })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe(svg => {
          this.src = this._domSanitizer.bypassSecurityTrustHtml(svg)
          this.iconService.icon = {
            [this.name]: this.src,
          }
          this._changeDetectorRef.detectChanges()
        })
    }
  }

  /**
   * Event classes button
   * @returns {string[]}
   */
  public getClasses(): string[] {
    return [`${this.color}`, `size-${this.size}`]
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(true)
    this._unsubscribeAll.complete()
  }
}
