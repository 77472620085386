import { IPaymentCollection } from '@modules/payment-collection/interfaces/payment-collection'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import * as PaymentCollectionActions from '@modules/payment-collection/state/actions/payment-collection.actions'

export interface StatePaymentCollection extends EntityState<IPaymentCollection> {
  selectPaymentCollectionId?: string | number | null
  loading: boolean
}

export const adapter: EntityAdapter<IPaymentCollection> = createEntityAdapter<IPaymentCollection>()

export const initialState: StatePaymentCollection = adapter.getInitialState(<StatePaymentCollection>{
  loading: false,
})

export const paymentCollectionReducer = createReducer(
  initialState,
  on(PaymentCollectionActions.loadingPaymentCollection, (state, { loading }) => {
    return { ...state, loading: loading }
  }),
  on(PaymentCollectionActions.selectPaymentCollection, (state, { id }) => {
    return { ...state, selectPaymentCollectionId: id }
  }),
  on(PaymentCollectionActions.mapPaymentCollection, (state, { entityMap }) => {
    return adapter.mapOne(entityMap, state)
  }),
  on(PaymentCollectionActions.updatePaymentCollection, (state, { update }) => {
    return adapter.updateOne(update, state)
  }),
  on(PaymentCollectionActions.mapPaymentCollections, (state, { entityMap }) => {
    return adapter.map(entityMap, state)
  }),
  on(PaymentCollectionActions.loadPaymentCollections, (state, { paymentCollections }) => {
    return adapter.setAll(paymentCollections, state)
  }),
  on(PaymentCollectionActions.setPaymentCollections, (state, { paymentCollections }) => {
    return adapter.setMany(paymentCollections, state)
  }),
  on(PaymentCollectionActions.clearPaymentCollections, state => {
    return adapter.removeAll({ ...state, selectPaymentCollectionsId: null })
  })
)

// get the selectors
export const {
  // select the array of payment collections
  selectAll: selectAllPaymentCollections,
} = adapter.getSelectors()
