import { Injectable } from '@angular/core'
import { BaseResource } from '@shared/resources/base.resource'
import {
  IResourceMethodObservable,
  ResourceAction,
  ResourceParams,
  ResourceRequestBodyType,
  ResourceRequestMethod,
} from '@ngx-resource/core'

@Injectable({ providedIn: 'root' })
@ResourceParams({
  pathPrefix: '',
})
export class ConfigurationResource extends BaseResource {
  @ResourceAction({
    pathPrefix: 'v3/settings',
    method: ResourceRequestMethod.Get,
  })
  override get: IResourceMethodObservable<any, object> | any

  @ResourceAction({
    pathPrefix: 'v3/settings',
    method: ResourceRequestMethod.Put,
  })
  override update: IResourceMethodObservable<any, object> | any

  @ResourceAction({
    pathPrefix: 'v3/settings/profile_picture',
    method: ResourceRequestMethod.Put,
    requestBodyType: ResourceRequestBodyType.FORM_DATA,
    headers: {
      'Content-Type': null,
    },
  })
  updateProfileImage: IResourceMethodObservable<any, object> | any

  @ResourceAction({
    pathPrefix: 'v2/users/profile/verify',
    method: ResourceRequestMethod.Post,
  })
  override store: IResourceMethodObservable<any, object> | any
}
