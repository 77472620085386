import { createAction, props } from '@ngrx/store'
import { EntityMap, EntityMapOne, Update } from '@ngrx/entity'
import { ICostCenter, IDeduction } from '@modules/deduction/interfaces/deduction'

export const loadingDeduction = createAction('[Deduction/API] Loading Deductions', props<{ loading: boolean }>())
export const loadDeductions = createAction('[Deduction/API] Load Deductions', props<{ deductions: IDeduction[] }>())
export const selectDeduction = createAction('[Users Page] Select Deductions', props<{ id: string }>())
export const setDeductions = createAction('[Deduction/API] Set Deductions', props<{ deductions: IDeduction[] }>())
export const updateDeduction = createAction('[Deduction/API] Update Deduction', props<{ update: Update<IDeduction> }>())
export const mapDeduction = createAction(
  '[Deduction/API] Map Deduction',
  props<{ entityMap: EntityMapOne<IDeduction> }>()
)
export const mapDeductions = createAction(
  '[Deduction/API] Map Deductions',
  props<{ entityMap: EntityMap<IDeduction> }>()
)
export const setCostCenters = createAction(
  '[Deduction/API] Set Cost Centers',
  props<{ cost_centers: Record<string, ICostCenter> }>()
)
export const addFutureDeductions = createAction(
  '[Deduction/API] Add Future Deductions',
  props<{ entitites: IDeduction[] }>()
)
export const clearDeductions = createAction('[Deduction/API] Clear Deductions')
