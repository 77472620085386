import { Injectable } from '@angular/core'
import { environment } from '@environments/environment'
import { UserType } from '@modules/site/interfaces/site'
import { CookieService } from 'ngx-cookie-service'
import { Store } from '@ngrx/store'
import { StateCompany } from '@modules/company/state/reducers/company.reducer'

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private userType!: UserType | null
  private domain: string = ''

  constructor(private _cookieService: CookieService, protected _store: Store<{ company: StateCompany }>) {
    const hostname = window.location.hostname.split('.')
    this.domain = `.${hostname[hostname.length - 2]}.${hostname[hostname.length - 1]}`
  }

  setRoles(roles: UserType[]): Promise<void> {
    return Promise.resolve(
      this._cookieService.set(environment.COOKIE.ROLES, JSON.stringify(roles), undefined, '/', this.domain)
    )
  }

  getRoles(): UserType[] {
    return JSON.parse(decodeURIComponent(this._cookieService.get(environment.COOKIE.ROLES) || '[]'))
  }

  getSession(): Promise<string | null> {
    return Promise.resolve(this._cookieService.get(environment.COOKIE.APPLICATION) || null)
  }

  getSessionRefresh(): Promise<string | null> {
    return Promise.resolve(this._cookieService.get(environment.COOKIE.APPLICATION_REFRESH) || null)
  }

  setSession(token: string): Promise<void> {
    this.deleteSession()
    return Promise.resolve(this._cookieService.set(environment.COOKIE.APPLICATION, token, undefined, '/', this.domain))
  }

  deleteSession(): Promise<void> {
    this._cookieService.delete(environment.COOKIE.APPLICATION)
    return Promise.resolve()
  }

  deleteSessionRefresh(): Promise<void> {
    this._cookieService.delete(environment.COOKIE.APPLICATION_REFRESH)
    return Promise.resolve()
  }

  setSessionRefresh(token: string): Promise<void> {
    this.deleteSessionRefresh()
    return Promise.resolve(
      this._cookieService.set(environment.COOKIE.APPLICATION_REFRESH, token, undefined, '/', this.domain)
    )
  }

  remove2fa() {
    this._cookieService.delete(environment.COOKIE.SESSION_IDENTIFIER, '/', this.domain)
    this._cookieService.delete(environment.COOKIE.SESSION, '/', this.domain)
    this._cookieService.delete(environment.COOKIE.SESSION_CHALLENGE_NAME, '/', this.domain)
  }

  destroySession(): Promise<void> {
    this._cookieService.delete(environment.COOKIE.ROLES, '/', this.domain)
    this._cookieService.delete(environment.COOKIE.APPLICATION, '/', this.domain)
    this._cookieService.delete(environment.COOKIE.APPLICATION_REFRESH, '/', this.domain)
    return Promise.resolve()
  }

  getRole(): UserType | null {
    return this.userType
  }

  getType(): UserType | null {
    if (!this.userType) return null
    switch (this.userType) {
      case UserType.SUPERADMIN:
      case UserType.SAAS_SUPERADMIN:
        return UserType.SUPERADMIN
      default:
        return UserType.ADMIN
    }
  }

  recoveryRole(): UserType | null {
    const roles = this.getRoles()
    if (roles.includes(UserType.FINANCE_ADMIN)) {
      this.userType = UserType.FINANCE_ADMIN
    } else if (roles.includes(UserType.SUPERADMIN)) {
      this.userType = UserType.SUPERADMIN
    } else if (roles.includes(UserType.SAAS_SUPERADMIN)) {
      this.userType = UserType.SUPERADMIN
    } else if (roles.includes(UserType.ADMIN)) {
      this.userType = UserType.ADMIN
    }
    return this.userType
  }

  defineRole(): UserType | null {
    this.recoveryRole()
    return this.userType
  }
}
