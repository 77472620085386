import { IGroup } from '@modules/group/interfaces/group'
import type { EntityAdapter } from '@ngrx/entity'
import { createEntityAdapter, EntityState } from '@ngrx/entity'
import { createReducer, on } from '@ngrx/store'
import * as GroupActions from '../actions/group.actions'

export interface StateGroup extends EntityState<IGroup> {
  selectGroupId?: string | number | null
  loading: boolean
}

export const adapter: EntityAdapter<IGroup> = createEntityAdapter<IGroup>()

export const initialState: StateGroup = adapter.getInitialState(<StateGroup>{
  loading: false,
})

export const groupReducer = createReducer(
  initialState,
  on(GroupActions.loadingGroup, (state, { loading }) => {
    return { ...state, loading: loading }
  }),
  on(GroupActions.selectGroup, (state, { id }) => {
    return { ...state, selectGroupId: id }
  }),
  on(GroupActions.mapGroup, (state, { entityMap }) => {
    return adapter.mapOne(entityMap, state)
  }),
  on(GroupActions.updateGroup, (state, { update }) => {
    return adapter.updateOne(update, state)
  }),
  on(GroupActions.mapGroups, (state, { entityMap }) => {
    return adapter.map(entityMap, state)
  }),
  on(GroupActions.loadGroups, (state, { groups }) => {
    return adapter.setAll(groups, state)
  }),
  on(GroupActions.setGroups, (state, { groups }) => {
    return adapter.setMany(groups, state)
  }),
  on(GroupActions.clearGroups, state => {
    return adapter.removeAll({ ...state, selectGroupId: null })
  })
)

// get the selectors
export const {
  // select the array of collaborators
  selectAll: selectAllGroups,
} = adapter.getSelectors()
