import { ConfigurationResource } from '@modules/configuration/configuration.resource'
import { Injectable } from '@angular/core'
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import type { Observable } from 'rxjs'
import { debounceTime } from 'rxjs'
import { finalize, map, switchMap, tap } from 'rxjs/operators'
import { StateApp } from '@shared/state/reducers/app.reducer'
import { AuthResource } from '@shared/resources/auth.resource'
import * as AppActions from '../actions/app.actions'
import { clearCatalog, loadingApp, setCompany, setCompanyCreditSettings, updateCompany } from '../actions/app.actions'
import {
  selectAppCompany,
  selectAppCompanyCreditSettings,
} from '@shared/state/selectors/app.selectors'
import { ICompany } from '@modules/company/interfaces/company'
import { SessionService } from '@services/session.service'
import { loadAdmins } from '@modules/configuration/state/actions/admins.actions'
import { CreditSettingResource } from '@modules/credit/credit-setting.resource'
import { ICreditSetting } from '@modules/credit/interfaces/credit'

@Injectable()
export class AppEffects {
  loadCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.loadCompany),
      tap(() => {
        this._store.dispatch(loadingApp({ loading: true }))
      }),
      switchMap((): Observable<any> => {
        return this._authResource.myCompany().pipe(
          map((company: ICompany) => {
            return setCompany({ company: company })
          }),
          finalize(() => {
            this._store.dispatch(loadingApp({ loading: false }))
          })
        )
      })
    )
  })

  updateCompany$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AppActions.updateCompany),
        concatLatestFrom(() => [
          this._store.select(selectAppCompany),
          this._store.select(selectAppCompanyCreditSettings),
        ]),
        tap(([, data]): void => {
          this._store.dispatch(loadingApp({ loading: false }))
          this._store.dispatch(clearCatalog())
          if (data.company_time_zone) {
            localStorage.setItem('timezone', data.company_time_zone)
          }
          this._store.dispatch(
            loadAdmins({
              admins: [],
            })
          )
        }),
        tap(([, company, creditSetting]) => {
          if (company.is_credit_active && company?.id !== creditSetting?.company_id) {
            this._creditSettingResource
              .get({
                id: company.id,
              })
              .subscribe(({ data }: { data: ICreditSetting }) => {
                if (data.company_id === company.id) {
                  return this._store.dispatch(
                    setCompanyCreditSettings({
                      credit_settings: data,
                    })
                  )
                }
              })
          }
        })
      )
    },
    { dispatch: false }
  )

  loadCompanyData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.setCompany),
      tap(() => {
        this._store.dispatch(loadingApp({ loading: true }))
      }),
      concatLatestFrom(() => this._store.select(selectAppCompany)),
      switchMap(([, data]): Observable<any> => {
        return this._authResource
          .myCompanyData({
            id: data?.company_id,
          })
          .pipe(
            map((company: ICompany) => {
              return updateCompany({ company: company })
            }),
            finalize(() => {
              this._store.dispatch(loadingApp({ loading: false }))
            })
          )
      })
    )
  })

  loadUserData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.loadUser),
      debounceTime(500),
      tap(() => {
        this._store.dispatch(loadingApp({ loading: true }))
      }),
      switchMap((): Observable<any> => {
        return this._configurationResource.get().pipe(
          map((setting: any) => {
            return AppActions.updateUser({ user: { ...setting.data, role: this._sessionService.recoveryRole() } })
          }),
          finalize(() => {
            this._store.dispatch(loadingApp({ loading: false }))
          })
        )
      })
    )
  })

  constructor(
    private actions$: Actions,
    private _authResource: AuthResource,
    private _creditSettingResource: CreditSettingResource,
    private _configurationResource: ConfigurationResource,
    private _store: Store<{ app: StateApp }>,
    protected _sessionService: SessionService
  ) {}
}
