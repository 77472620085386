import { Injectable } from '@angular/core'
import { CanActivate, Router, UrlTree } from '@angular/router'
import { Observable, of, take } from 'rxjs'
import { SessionService } from '@services/session.service'
import { UserType } from '@modules/site/interfaces/site'
import { selectAppCompany } from '@shared/state/selectors/app.selectors'
import { Store } from '@ngrx/store'
import { StateGroup } from '@modules/group/state/reducers/group.reducer'
import { StateApp } from '@shared/state/reducers/app.reducer'
import { ICompany } from '@modules/company/interfaces/company'
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class CompanyGuard implements CanActivate {
  constructor(
    private router: Router,
    private _sessionService: SessionService,
    protected _store: Store<{ group: StateGroup; app: StateApp }>
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this._sessionService.recoveryRole()
    if (this._sessionService.getRole() === UserType.SUPERADMIN) {
      return this._store.select(selectAppCompany).pipe(
        map((company: ICompany) => {
          if (company) {
            return true
          }
          return this.router.parseUrl('/company/list')
        }),
        take(1)
      )
    }
    return of(true)
  }
}
