import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import InputTextComponent from './input-text.component'
import { CommonModule } from '@angular/common'

@NgModule({
  declarations: [InputTextComponent],
  exports: [InputTextComponent],
  imports: [ReactiveFormsModule, FormsModule, CommonModule],
})
export class InputTextModule {}
