import { createAction, props } from '@ngrx/store'
import { ICompany } from '@modules/company/interfaces/company'
import { IUser } from '@modules/configuration/interfaces/configuration'
import { ICreditSetting } from '@modules/credit/interfaces/credit'

export const loadingApp = createAction('[App/API] Loading App', props<{ loading: boolean }>())
export const loadCompany = createAction('[App/API] Load Company')
export const setCompany = createAction('[App] Set Company', props<{ company: ICompany }>())
export const updateCompany = createAction('[App] Update Company', props<{ company: ICompany }>())
export const setCompanyCreditSettings = createAction(
  '[App] Set Company Credit Settings',
  props<{ credit_settings: ICreditSetting }>()
)
export const clearCompany = createAction('[App] Clear Company')
export const setUser = createAction('[App] Set User', props<{ user: IUser }>())
export const updateUser = createAction('[App] Update User', props<{ user: Partial<IUser> }>())
export const loadUser = createAction('[App/API] Load User')
export const clearApp = createAction('[App] Clear App')
export const setCatalog = createAction('[App] Set Catalogs App', props<{ catalog: any }>())
export const clearCatalog = createAction('[App] Clear Catalogs App')
