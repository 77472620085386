import { AbstractControl, AbstractControlOptions, FormBuilder } from '@angular/forms'
import { Injectable } from '@angular/core'

@Injectable()
export class BaseFormBuilder extends FormBuilder {
  timeOuts: any = {}

  // @ts-ignore
  override group(controlsConfig: { [key: string]: any }, options: AbstractControlOptions | null = null) {
    const formGroup = super.group(controlsConfig, options)
    const debounceTimeMs = 2000
    const that = this
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key) as AbstractControl
      const originalUpdateValueAndValidity = control.updateValueAndValidity
      control.updateValueAndValidity = function updateValueAndValidityFn(opts?: {
        onlySelf?: boolean
        emitEvent?: boolean
      }): void {
        clearTimeout(that.timeOuts[key])
        //get errors before update
        const errorsBefore = control.errors
        //calculate new errors
        originalUpdateValueAndValidity.call(control, opts)

        //get invalid flag after update
        const invalid = control.invalid

        //if invalid and no errors before, set errors to null
        if (invalid) {
          if (control.touched && control.dirty && !control.value) {
            originalUpdateValueAndValidity.call(control, opts)
            return control.markAsDirty()
          }
          if (!Object.keys(errorsBefore || {}).length && Object.keys(control.errors || {}).length) {
            control.markAsPristine()
          } else if (Object.keys(control.errors || {}).length) {
            control.markAsPristine()
          }
          //set errors to null after debounce time
          that.timeOuts[key] = setTimeout(() => {
            originalUpdateValueAndValidity.call(control, opts)
            control.markAsDirty()
          }, debounceTimeMs)
        } else {
          originalUpdateValueAndValidity.call(control, opts)
        }
      }
    })

    return formGroup
  }
}
