import { createAction, props } from '@ngrx/store'
import { EntityMap, EntityMapOne, Update } from '@ngrx/entity'
import { IGroup } from '@modules/group/interfaces/group'

export const loadingGroup = createAction('[Group/API] Loading Groups', props<{ loading: boolean }>())
export const loadGroups = createAction('[Group/API] Load Groups', props<{ groups: IGroup[] }>())
export const selectGroup = createAction('[Users Page] Select Groups', props<{ id: string }>())
export const setGroups = createAction('[Group/API] Set Groups', props<{ groups: IGroup[] }>())
export const updateGroup = createAction('[Group/API] Update Group', props<{ update: Update<IGroup> }>())
export const mapGroup = createAction('[Group/API] Map Group', props<{ entityMap: EntityMapOne<IGroup> }>())
export const mapGroups = createAction('[Group/API] Map Groups', props<{ entityMap: EntityMap<IGroup> }>())
export const clearGroups = createAction('[Group/API] Clear Groups')
