import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { IconModule } from '@atom/icon/icon.module'
import { ModalComponent } from '@molecule/modal/modal.component'
import { ModalStackService } from '@molecule/modal/services/modal-stack.service'

@NgModule({
  declarations: [ModalComponent],
  imports: [CommonModule, IconModule],
  exports: [ModalComponent],
  providers: [ModalStackService],
})
export class ModalModule {}
